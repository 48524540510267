import React, { forwardRef, useContext, useImperativeHandle, useState } from 'react'
import { CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, Table, TableBody, TableCell, TableHead, TableRow, TextField, Typography } from '@material-ui/core'
import { AddCircle, Cancel } from '@material-ui/icons'
import { Autocomplete } from '@material-ui/lab'
import { UserTypeContext } from '../../AppRoute'
// import getRecruiterByTeamLeader from '../../apis/getRecruitersByTeam/getRecruiterByTeamLeader'
import configJSON from "../../Config.json";
import {
    NotificationContainer,
    NotificationManager,
} from "react-notifications";
import assignRecruiterRequisition from '../../apis/assignRecruiterRequisition/assignRecruiterRequisition'
import checkRequisitionExists from '../../apis/checkRequisitionExists/checkRequisitionExists'
import { reassignRecruiter } from '../../apis/assignRecruiter/assignRecruiter'
import getRecruiterByTeam from '../../apis/getRecruitersByTeam/getRecruiterByTeam'

const RecruiterRequisitionAssignment = forwardRef((props, ref) => {

    const { userType, userRecords } = useContext(UserTypeContext);
    const userRecruitmentpartnerId = userType === 'Admin' ? props?.location?.state?.recruitmentPartnerId : userRecords?.recruitmentpartnerId;
    const { recruiterModalIsOpen, requisitionResponseData, closeRecruiterRequisitionModal, setRecruiterModalIsOpen, status, getRequisitionDetail, selectedTeam } = props
    const idContextTest = userType === "Admin" ? props?.location?.state?.id : userRecords?.id
    const [recruiterAssignment, setRecruiterAssignment] = useState([])
    const [recruiterOptions, setRecruiterOptions] = useState([])
    const [isUserDataUpdating, setIsUserDataUpdating] = useState(false)
    const [isReassignModelOpen, setIsReassignModelOpen] = useState(false)
    const [reAssignmentObj, setReAssignmentObj] = useState({
        reassginedFrom: {},
        reassginedTo: {},
        isReassignToOptionFetching: false
    })
    const [reassignRecruiterList, setReassignRecruiterList] = useState([])
    const [reassignToRecruiterList, setReassignToRecruiterList] = useState([])


    const addRecruiterAssigment = () => {

        let assignedRecruiters = [];
        if (userType === "Team Leader" || userType === "Recruiter") {
            assignedRecruiters = requisitionResponseData?.recruiterRequisitionDetails || [];
        }
        else if (userType === "MIS Operator" || userType === "Organization Admin" || userType === "Recruitment Leader") {
            assignedRecruiters = requisitionResponseData?.recruiterRequisitionDetails || [];
            assignedRecruiters = assignedRecruiters.filter(item => item.team.teamId === selectedTeam._id)
        }

        if (assignedRecruiters.length > 0) {
            let arr = [];
            // let defaultTeamData = selectedTeam
            let recruiterDetailsData = requisitionResponseData?.recruiterDetails || [];
            assignedRecruiters.forEach(item => {
                arr.push({
                    team: getDefaultTeamData(),
                    recruiter: recruiterDetailsData.find(val => val.id === item.recruiterId),
                    recruiterOptionFetching: false,
                    noOfPositions: item.no_of_positions
                })
            })
            setRecruiterAssignment(arr)
        }
        else {
            addRecruiterAssigmentRow();
        }
    }

    const addRecruiterAssigmentRow = () => {
        setRecruiterAssignment([...recruiterAssignment,
        {
            team: getDefaultTeamData(),
            recruiter: {},
            recruiterOptionFetching: false,
            noOfPositions: ""
        }])
    }

    const getDefaultTeamData = () => {

        let teamData = {}

        if (userType === "Team Leader") {
            let teamLeaderRequistionRefData = requisitionResponseData?.teamLeaderRequisitionDetails || [];
            teamLeaderRequistionRefData = teamLeaderRequistionRefData.find(item => item.team.teamLeaderId === userRecords.id);
            teamData = teamLeaderRequistionRefData?.teamDetails;
        }
        else if (userType === "MIS Operator" || userType === "Organization Admin" || userType === "Recruitment Leader") {
            teamData = selectedTeam;
        }
        return teamData;

    }

    const extraRecruiterAssignmentClose = async (index) => {
        let originalData = [...recruiterAssignment];
        let defaultTeam =getDefaultTeamData();

        let payload = {
            validation: "RecruiterCandidateRequisition",
            recruiterId: originalData[index]?.recruiter?.id,
            requisitionDetailId: requisitionResponseData?._id,
            recruitmentpartnerId: userRecruitmentpartnerId,
            teamId: defaultTeam?._id,
        }
        let havingAssignedCandidates = false;
        try {
            let response = await checkRequisitionExists(payload);
            if (response.status === 200) {
                let assignedCandidates = response?.data?.data[0]?.candidateRequisitions || [];
                if (assignedCandidates.length > 0) {
                    assignedCandidates = assignedCandidates.filter(item => item.isActive === true);
                    if (assignedCandidates.length > 0) {
                        havingAssignedCandidates = true;
                        NotificationManager.error("Recruiter already having some assigned candidates for this requisition", "Error", 3500);
                        return;
                    }
                }
            }
            else {
                NotificationManager.error("Error in fetching recruiter's assigned candidate data", "Error", 3500);
                return;
            }
            if (!havingAssignedCandidates) {
                originalData.splice(index, 1);
                setRecruiterAssignment(originalData);
            }
        } catch (error) {
            NotificationManager.error("Something went wrong", "Error", 2500);
        }
    }

    const clearRecruiterRequistionData = () => {
        setRecruiterAssignment([]);
    }

    const getRecruiterOptions = (recruitersData) => {
        let recruiterOptionsData = [];

        recruitersData.forEach(item => {
            const assignedRecruiters = recruiterAssignment.filter(val => val.recruiter.id === item.id);
            if (assignedRecruiters.length === 0) {
                recruiterOptionsData.push(item);
            }
        });

        return recruiterOptionsData;
    }

    const getRemovedRecruiterIds = () => {
        let removedRecruiterIds = [];
        let notificationList = [];
        let recruiterLogList = [];
        let allRecruiterAssignmentData = requisitionResponseData?.recruiterRequisitionDetails || [];
        let teamData = getDefaultTeamData();
        allRecruiterAssignmentData = allRecruiterAssignmentData.filter(item => item.team.teamId === teamData._id)
        if (allRecruiterAssignmentData.length) {
            allRecruiterAssignmentData.forEach((item) => {
                let noOfPositions;
                let isExist = recruiterAssignment.some((assignment) => {
                    if (assignment.recruiter?.id === item.recruiterId) {
                        noOfPositions = parseInt(assignment.noOfPositions) // Store the position when isExist is true
                        return true;  // Return true to indicate that the recruiter exists
                    }
                    return false;  // Return false if recruiter doesn't match
                });
                if (!isExist) {
                    removedRecruiterIds.push(item?.recruiterId);
                    const tlobj = {}
                    tlobj.id = item?.recruiterId;
                    tlobj.email = item?.email;
                    tlobj.displayName = item?.displayName;
                    // tlobj.message = "Requisition with Reference ID : " + requisitionResponseData.reqreferenceId + " " + " is reassigned by " + displayName;
                    tlobj.status = -1 //
                    notificationList.push(tlobj)
                } else {
                    if (item?.no_of_positions != noOfPositions) {
                        const tlobj = {}
                        tlobj.id = item?.recruiterId;
                        tlobj.email = item?.email;
                        tlobj.displayName = item?.displayName;
                        // tlobj.message = "Requisition with Reference ID : " + requisitionResponseData.reqreferenceId + " " + " is reassigned by " + displayName;
                        tlobj.status = 1 //already added
                        notificationList.push(tlobj);
                        recruiterLogList.push(item?.recruiterId);
                    }

                }
            });
            const newTlList = recruiterAssignment.filter(item2 => !allRecruiterAssignmentData.some(item1 => item1?.recruiterId === item2.recruiter?.id));
            newTlList.forEach((item) => {
                const tlobj = {}
                tlobj.id = item?.recruiter?.id;
                tlobj.email = item?.recruiter?.email;
                tlobj.displayName = item?.recruiter?.displayName;
                // tlobj.message = "Requisition with Reference ID : " + requisitionResponseData.reqreferenceId + " " + " is reassigned by " + displayName;
                tlobj.status = 0 //newly added
                notificationList.push(tlobj);
                recruiterLogList.push(item?.recruiter?.id);
            })
        } else {
            recruiterAssignment.forEach((item) => {
                const tlobj = {}
                tlobj.id = item?.recruiter?.id;
                tlobj.email = item?.recruiter?.email;
                tlobj.displayName = item?.recruiter?.displayName;
                // tlobj.message = "Requisition with Reference ID : " + requisitionResponseData.reqreferenceId + " " + " is reassigned by " + displayName;
                tlobj.status = 0  ////newly added
                notificationList.push(tlobj);
                recruiterLogList.push(item?.recruiter?.id);
            })
        }
        return { "removedRecruiterIds": removedRecruiterIds, "notificationList": notificationList, "recruiterLogList": recruiterLogList };
    }


    const fetchRecruiterOptions = async (item, index) => {
        setRecruiterOptions([]);
        let oldRecruiterArray = [...recruiterAssignment];
        oldRecruiterArray[index].recruiterOptionFetching = true;
        setRecruiterAssignment(oldRecruiterArray);
        // let teamLeaderId = item?.teamLeader?.id;
        let teamId = item?.team?._id;
        try {
            let response = await getRecruiterByTeam(teamId);
            let recruiterData = response?.data.data[0]?.recruiterDetails;
            oldRecruiterArray[index].recruiterOptionFetching = false;
            let filteredData = getRecruiterOptions(recruiterData);
            setRecruiterOptions(filteredData);
            setRecruiterAssignment(oldRecruiterArray);
        } catch (error) {
            NotificationManager.error("Error in fetching recruiter options", "Error", 2000);
        }
    }

    const recruiterChangeHandler = async (value, index) => {

        try {
            let oldRecruiterArray = [...recruiterAssignment];
            let recruiterData = oldRecruiterArray[index].recruiter;
            let havingAssignedCandidates = false;
            let defaultTeam =getDefaultTeamData();


            let payload = {
                validation: "RecruiterCandidateRequisition",
                recruiterId: recruiterData.id,
                requisitionDetailId: requisitionResponseData?._id,
                recruitmentpartnerId: userRecruitmentpartnerId,
                teamId: defaultTeam._id
            }

            let response = await checkRequisitionExists(payload);

            if (response.status === 200) {
                if (response?.data?.data[0]?.candidateRequisitions?.length > 0) {
                    havingAssignedCandidates = true;
                    NotificationManager.error("The recruiter has already started his work for this requisition", "Error", 3500);
                    return;
                }
            }
            else {
                NotificationManager.error("Error in fetching recruiter data", "Error", 3500);
                return;
            }

            if (!havingAssignedCandidates) {

                if (value) {
                    oldRecruiterArray[index].recruiter = value;
                }
                else {
                    oldRecruiterArray[index].recruiter = {};
                }
                setRecruiterAssignment(oldRecruiterArray);
            }
            else {
                setRecruiterAssignment(oldRecruiterArray);
            }
        } catch (error) {
            NotificationManager.error("Something went wrong", "Error", 2500);
        }
    }

    const openingHandleChange = (e, index) => {
        let oldRecruiterArray = [...recruiterAssignment];
        if (e.target.value) {
            oldRecruiterArray[index].noOfPositions = parseInt(e.target.value);
        }
        else {
            oldRecruiterArray[index].noOfPositions = "";
        }
        setRecruiterAssignment(oldRecruiterArray);
    }

    const handleAssignRecruiter = async () => {
        let isvalid = true;
        let totalassignedPositions = 0;
        let errorMessage = "";
        let recruiterAssignmentData = [...recruiterAssignment];
        const openStatus = status.find(item => item.statusCode === configJSON?.requisitionStatusCodes?.OPEN);
        for (let i = 0; i < recruiterAssignmentData.length; i++) {
            let item = recruiterAssignmentData[i];

            if (Object.keys(item.team).length === 0 || Object.keys(item.recruiter).length === 0 || item.noOfPositions === "") {
                isvalid = false;
                errorMessage = "Please fill all the fields";
                break;
            }
            else if (item.noOfPositions <= 0) {
                isvalid = false;
                errorMessage = "Positions should be greater than 0";
                break;
            }
            try {
                let payloadData = {
                    validation: "RecruiterFilledCandidates",
                    recruiterId: item?.recruiter?.id,
                    requisitionDetailId: requisitionResponseData?._id,
                    recruitmentpartnerId: userRecruitmentpartnerId
                }
                let response = await checkRequisitionExists(payloadData);
                if (response.status === 200) {
                    if (response.data.data[0]?.candidateRequisitions?.length > item.noOfPositions) {

                        let candidateCount = response.data.data[0]?.candidateRequisitions?.length;
                        NotificationManager.error(
                            `Recruiter (${item?.recruiter?.email}) already has ${candidateCount} candidates as selected or started. Assigned positions cannot be fewer than ${candidateCount}`,
                            "Error",
                            4500
                        );
                        isvalid = false;
                        break;
                    }
                    else if (response.data.data[0]?.candidateRequisitions?.length === item.noOfPositions) {
                        const subStatus_covered = openStatus?.subList?.find(item => item.subCode === configJSON?.requisitionStatusCodes?.OPENSUBSTATUS?.COVERED);
                        recruiterAssignmentData[i].status = {
                            subCode: subStatus_covered.subCode,
                            statusCode: openStatus.statusCode,
                            statusCodeId: openStatus._id,
                            subCodeId: subStatus_covered._id
                        }
                    }
                    else if (response.data.data[0]?.candidateRequisitions?.length < item.noOfPositions) {
                        const subStatus_uncovered = openStatus?.subList?.find(item => item.subCode === configJSON?.requisitionStatusCodes?.OPENSUBSTATUS?.UNCOVERED);
                        recruiterAssignmentData[i].status = {
                            subCode: subStatus_uncovered.subCode,
                            statusCode: openStatus.statusCode,
                            statusCodeId: openStatus._id,
                            subCodeId: subStatus_uncovered._id
                        }
                    }
                    else {
                        const subStatus_uncovered = openStatus?.subList?.find(item => item.subCode === configJSON?.requisitionStatusCodes?.OPENSUBSTATUS?.UNCOVERED);
                        recruiterAssignmentData[i].status = {
                            subCode: subStatus_uncovered.subCode,
                            statusCode: openStatus.statusCode,
                            statusCodeId: openStatus._id,
                            subCodeId: subStatus_uncovered._id
                        }
                    }
                }
            } catch (error) {
                NotificationManager.error("Error in fetching recruiter's assigned candidates", "Error", 3500);
            }
            totalassignedPositions += parseInt(item.noOfPositions);
        }
        let totalPositions = getPositionsTobeAssigned();
        if (totalassignedPositions > totalPositions) {
            isvalid = false;
            return NotificationManager.error(`Total assigned positions should not be greater than ${totalPositions}`, "Error", 3500);
        }

        if (isvalid) {
            let teamLeaderRequisitionIds = []
            let payloadObj = [];
            for (let i = 0; i < recruiterAssignmentData.length; i++) {
                let teamLeaderRequistionReference = requisitionResponseData?.teamLeaderRequisitionDetails?.find(val => val.team.teamId === recruiterAssignmentData[i]?.team?._id)
                teamLeaderRequisitionIds.push(teamLeaderRequistionReference?._id)
                let obj = {
                    requisitionId: requisitionResponseData?.requisitionId,
                    requisitionDetailId: requisitionResponseData?._id,
                    requisitionLeaderId: requisitionResponseData?.recruiterLeaderRequisitionDetails?._id,
                    requisitionTeamLeaderId: teamLeaderRequistionReference?._id,
                    recruiterId: recruiterAssignmentData[i]?.recruiter?.id,
                    isBorrowed: teamLeaderRequistionReference?.isBorrowed,
                    isBorrowedExternally: teamLeaderRequistionReference?.isBorrowedExternally,
                    isBorrowedFromSameDiv: teamLeaderRequistionReference?.isBorrowedFromSameDiv,
                    team: teamLeaderRequistionReference?.team,
                    division: teamLeaderRequistionReference?.division,
                    status: recruiterAssignmentData[i]?.status,
                    isActive: true,
                    no_of_positions: parseInt(recruiterAssignmentData[i]?.noOfPositions)
                }
                payloadObj.push(obj)
            }


            let recLeadObj = getRemovedRecruiterIds();
            let removedRecruiterIds = recLeadObj.removedRecruiterIds;
            let istlnf = userType === "Team Leader" ? false : true
            let teamData = getDefaultTeamData();
            let payload = {
                recruitersData: payloadObj,
                teamLeaderRequisitionIds: teamLeaderRequisitionIds,
                requisitionDetailId: requisitionResponseData._id,
                recruitmentpartnerId: userRecruitmentpartnerId,
                removedRecruiterIds: removedRecruiterIds,
                notificationList: recLeadObj.notificationList,
                recruiterLogList: recLeadObj.recruiterLogList,
                senderId: idContextTest,
                reqreferenceId: requisitionResponseData.reqreferenceId,
                displayName: userRecords.displayName,
                // teamLeaderEmail: selectedTeamLeader.email,
                teamLeaderId: teamData?.teamLeaderId,
                userRecruitmentpartnerId: userRecruitmentpartnerId,
                userId: idContextTest,
                istlnf,
                requisitionId:requisitionResponseData.requisitionId,
                teamId:teamData?._id
            }

            try {
                setIsUserDataUpdating(true);
                let response = await assignRecruiterRequisition(payload);
                if (response.status === 200) {
                    NotificationManager.success(response.data.message, "Success", 2500);
                    setIsUserDataUpdating(false);
                    closeRecruiterRequisitionModal({ action: 'save' });
                    getRequisitionDetail();
                }
                else {
                    NotificationManager.error("An unexpected error occurred", "Error", 2500);
                    setIsUserDataUpdating(false);
                }
            } catch (error) {
                if (error.response.status === 400) {
                    NotificationManager.error(error?.response?.data?.errMessage, "Error", 2500);
                    setIsUserDataUpdating(false);
                }
                else {
                    NotificationManager.error("Error assinging recruiter", "Error", 2500);
                }

            }
        }
        else {
            NotificationManager.error(errorMessage, "Error", 3000);
        }

    }

    const getPositionsTobeAssigned = () => {
        let teamLeaderRequistionReference = {}
        if (userType === "Team Leader") {
            let teamDetailsData = getDefaultTeamData();
            teamLeaderRequistionReference = requisitionResponseData?.teamLeaderRequisitionDetails?.find(val => val.team.teamId === teamDetailsData._id)
        }
        else{
            teamLeaderRequistionReference = requisitionResponseData?.teamLeaderRequisitionDetails?.find(val => val.team.teamId === selectedTeam._id)
        }
        return teamLeaderRequistionReference?.no_of_positions
    }

    const openRecruiterReassignModel = () => {
        let arr = recruiterAssignment.map(item => item?.recruiter)
        setReassignRecruiterList(arr);
        setRecruiterModalIsOpen(false);
        setIsReassignModelOpen(true);
    }

    const closeRecruiterReassignModel = () => {
        setReassignRecruiterList([]);
        setReAssignmentObj({ reassginedFrom: {}, reassginedTo: {}, isReassignToOptionFetching: false });
        setRecruiterModalIsOpen(true);
        setIsReassignModelOpen(false);
        setReassignToRecruiterList([]);
    }

    const fromReassignmentChangeHandler =async (value) => {
        let obj = { ...reAssignmentObj };
        if (value) {
            obj.reassginedFrom = value;
            obj.reassginedTo = {};
        }
        else {
            obj.reassginedFrom = {};
            obj.reassginedTo = {};
        }
        setReAssignmentObj(obj);
    }

    const newRecruiterReassignmentChangeHandler = (value) => {
        let obj = { ...reAssignmentObj };
        if (value) {
            obj.reassginedTo = value;
        }
        else {
            obj.reassginedTo = {};
        }
        setReAssignmentObj(obj);
    }

    const fetchRecruiterOptions_reassignment = async () => {
        let teamId = selectedTeam._id;
        setReAssignmentObj({ ...reAssignmentObj, isReassignToOptionFetching: true });
        try {
            let response = await getRecruiterByTeam(teamId);
            let recruiterData = response?.data.data[0]?.recruiterDetails;
            let filteredData = recruiterData.filter(item => item.id !== reAssignmentObj.reassginedFrom.id);
            setReassignToRecruiterList(filteredData);
            setReAssignmentObj({ ...reAssignmentObj, isReassignToOptionFetching: false });
        } catch (error) {
            NotificationManager.error("Error in fetching recruiter options", "Error", 2000);
        }
    }

    const handleReassignment = async () => {
        try {
            if (Object.keys(reAssignmentObj.reassginedFrom).length === 0 || Object.keys(reAssignmentObj.reassginedTo).length === 0) {
                NotificationManager.error("Please fill all the fields", "Error", 2000);
                return;
            }
            else {
                setIsUserDataUpdating(true);
                let istlnf = userType === "Team Leader" ? false : true
                let teamData = getDefaultTeamData();
                let notificationList = [{ "id": reAssignmentObj.reassginedFrom.id, "email": reAssignmentObj.reassginedFrom.email, "displayName": reAssignmentObj.reassginedFrom.displayName, "status": -1 },
                { "id": reAssignmentObj.reassginedTo.id, "email": reAssignmentObj.reassginedTo.email, "displayName": reAssignmentObj.reassginedTo.displayName, "status": 0 }];
                let payloadData = {
                    requisitionDetailId: requisitionResponseData._id,
                    requisitionId: requisitionResponseData.requisitionId,
                    oldRceruiterId: reAssignmentObj.reassginedFrom.id,
                    newRecruiterId: reAssignmentObj.reassginedTo.id,
                    notificationList: notificationList,
                    senderId: idContextTest,
                    reqreferenceId: requisitionResponseData.reqreferenceId,
                    displayName: userRecords.displayName,
                    teamLeaderId: selectedTeam.teamLeaderId,
                    recruitmentpartnerId: userRecruitmentpartnerId,
                    istlnf,
                    teamId:teamData._id
                }

                let payload = {
                    reassignList: [payloadData],
                    userRecruitmentpartnerId: userRecruitmentpartnerId,
                    userId: idContextTest,
                }

                let response = await reassignRecruiter(payload);
                
                if (response.status === 200) {
                    NotificationManager.success(response.data.message, "Success", 2000);
                    setIsUserDataUpdating(false);
                    closeRecruiterReassignModel();
                    closeRecruiterRequisitionModal({ action: 'save' });
                    getRequisitionDetail();
                }
                else {
                    NotificationManager.error("An unexpected error occurred", "Error", 2500);
                    setIsUserDataUpdating(false);
                }
            }
        } catch (error) {
            if (error.response.status === 400) {
                NotificationManager.error(error?.response?.data?.errMessage, "Error", 2500);
                setIsUserDataUpdating(false);
            }
            else {
                NotificationManager.error("Error in reassigning recruiter", "Error", 2000);
            }
        }
    }

    // Used to make the function available to the parent component
    useImperativeHandle(ref, () => ({
        addRecruiterAssigment,
        clearRecruiterRequistionData
    }));



    return (
        <>
            <Dialog
                onClose={closeRecruiterRequisitionModal}
                aria-labelledby="customized-dialog-title"
                maxWidth='xl'
                open={recruiterModalIsOpen}
                className='dialogBox_assignRequisition'
            >
                <DialogTitle id="customized-dialog-title" className="header" onClose={closeRecruiterRequisitionModal}>
                    <div className='header_top'>
                        Recruiter Assignment
                    </div>
                </DialogTitle>
                <DialogContent className='assignRequisitionContainer'>
                    {userType !== "Recruiter" ? <div className='positionsTag_assignRecruiter'>Total assigned positions : {getPositionsTobeAssigned()}</div> : ""}
                    <Table>
                        <TableHead>
                            <TableRow>
                                {userType !== "Recruiter" ? <TableCell>Team*</TableCell> : ""}
                                <TableCell>Recruiter Email*</TableCell>
                                <TableCell>Recruiter Name*</TableCell>
                                <TableCell>No of positions*</TableCell>
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {recruiterAssignment.map((item, i) => (
                                <TableRow>
                                    {userType !== "Recruiter" ?
                                        <TableCell>
                                            <FormControl
                                                fullWidth variant="outlined">
                                                <Autocomplete
                                                    id="multiple-limit-tags"
                                                    className='input-text-3'
                                                    options={[item.team]}
                                                    getOptionLabel={(option) =>
                                                        option.teamName ? option?.teamName : ""
                                                    }
                                                    filterSelectedOptions
                                                    disableClearable
                                                    disabled={true}
                                                    value={item.team}
                                                    renderInput={(params) =>
                                                        <TextField
                                                            {...params}
                                                            variant="outlined"
                                                            required={true}
                                                            placeholder="Select Team Leader"
                                                        />
                                                    }
                                                />
                                            </FormControl>
                                        </TableCell>
                                        : null}

                                    <TableCell>
                                        <FormControl
                                            fullWidth variant="outlined">
                                            <Autocomplete
                                                id="multiple-limit-tags"
                                                className='input-text-3'
                                                options={recruiterOptions}
                                                getOptionLabel={(option) =>
                                                    option.email ? option?.email : ""
                                                }
                                                value={item.recruiter}
                                                loading={item.recruiterOptionFetching}
                                                onFocus={(e) => fetchRecruiterOptions(item, i)}
                                                filterSelectedOptions
                                                disabled={userType === "Recruiter" ? true : false}
                                                onChange={(e, val) => recruiterChangeHandler(val, i)}
                                                renderInput={(params) =>
                                                    <TextField
                                                        {...params}
                                                        variant="outlined"
                                                        required={true}
                                                        placeholder="Select Recruiter"
                                                    />
                                                }
                                            />
                                        </FormControl>
                                    </TableCell>

                                    <TableCell>
                                        <FormControl fullWidth variant="outlined">
                                            <TextField
                                                id="outlined-basic"
                                                variant="outlined"
                                                placeholder="Recruiter Name"
                                                value={Object.keys(item.recruiter).length > 0 ? item.recruiter.displayName : ""}
                                                disabled={true}
                                                className='recruiterName_teamLeader'
                                            />
                                        </FormControl>
                                    </TableCell>

                                    <TableCell className='openings_RecruiterRequisitionContainer'>
                                        <FormControl variant="filled">
                                            <TextField
                                                id="outlined-basic"
                                                variant="outlined"
                                                type='number'
                                                placeholder="Enter positions"
                                                value={item.noOfPositions}
                                                disabled={userType === "Recruiter" ? true :!(recruiterAssignment[i].team.recruiterId.includes(recruiterAssignment[i].recruiter.id))}
                                                className='recruiterAssignment_teamLeader'
                                                onChange={(e) => openingHandleChange(e, i)}
                                            />
                                        </FormControl>

                                        {recruiterAssignment.length > 1 && (
                                            <Cancel
                                                className="cancelIcon_teamLeaderReq"
                                                onClick={(e) => extraRecruiterAssignmentClose(i, item)}
                                            />
                                        )}
                                    </TableCell>
                                </TableRow>
                            ))}

                            {userType !== "Recruiter" && (
                                <TableRow>
                                    <TableCell colSpan={4}>
                                        <AddCircle
                                            color="primary"
                                            onClick={(e) => addRecruiterAssigmentRow(e)}
                                            className="addIcon_teamLeaderAssignment"
                                        />
                                    </TableCell>
                                </TableRow>)
                            }
                        </TableBody>
                    </Table>

                </DialogContent>

                <DialogActions className="dialog-actionbutton-assignTeamLeader">
                    {(userType === "MIS Operator" || userType === "Organization Admin" || userType === "Recruitment Leader") && (
                        <button className="reassign-button-recruiter" onClick={() => openRecruiterReassignModel()}>
                            Reassignment
                        </button>
                    )}

                    <button className="cancel-button-assignTeamLeader" onClick={() => closeRecruiterRequisitionModal()}>
                        Cancel
                    </button>
                    {userType !== "Recruiter" && (
                        <button
                            className={isUserDataUpdating ? 'updateButton-assignTeamLeader_disabled' : 'updateButton-assignTeamLeader'}
                            onClick={(e) => handleAssignRecruiter()}
                            disabled={isUserDataUpdating}
                        >
                            <span>Save</span>
                            {isUserDataUpdating ? (<CircularProgress className="circularProgress_updateButton" />) : null}
                        </button>)}
                </DialogActions>
            </Dialog>

            <Dialog
                onClose={closeRecruiterReassignModel}
                aria-labelledby="customized-dialog-title"
                width='lg'
                fullWidth={true}
                open={isReassignModelOpen}
                className='dialogBox_reassignRecruiter'
            >

                <DialogTitle id="customized-dialog-title" className="header">
                    <div className='header_top'>
                        Recruiter Reassignment
                    </div>
                </DialogTitle>
                <DialogContent className='reassignRecruiterContainer'>
                    <Typography className='label_recruiterReassignment'>Recruiter *</Typography>
                    <FormControl
                        fullWidth variant="outlined">
                        <Autocomplete
                            id="multiple-limit-tags"
                            className='input-text-3'
                            options={reassignRecruiterList}
                            getOptionLabel={(option) =>
                                option?.email ? option?.email : ""
                            }
                            filterSelectedOptions
                            disableClearable
                            onChange={(e, value) => fromReassignmentChangeHandler(value)}
                            value={reAssignmentObj.reassginedFrom}
                            renderInput={(params) =>
                                <TextField
                                    {...params}
                                    variant="outlined"
                                    required={true}
                                    placeholder="Select Recruiter"
                                />
                            }
                        />
                    </FormControl>
                    <Typography className='label_recruiterReassignment'>New recruiter *</Typography>
                    <FormControl
                        fullWidth variant="outlined">
                        <Autocomplete
                            id="multiple-limit-tags"
                            className='input-text-3'
                            options={reassignToRecruiterList}
                            getOptionLabel={(option) =>
                                option.email ? option?.email : ""
                            }
                            filterSelectedOptions
                            disableClearable
                            loading={reAssignmentObj.isReassignToOptionFetching}
                            onFocus={(e) => fetchRecruiterOptions_reassignment()}
                            onChange={(e, value) => newRecruiterReassignmentChangeHandler(value)}
                            renderInput={(params) =>
                                <TextField
                                    {...params}
                                    variant="outlined"
                                    required={true}
                                    placeholder="Select Recruiter"
                                />
                            }
                        />
                    </FormControl>

                    <Typography className='note_recruiterReassignment'>*<b>note: &nbsp;</b>
                        Candidates those are not is "selected" or "started" stages will be reassigned to a new recruiter.
                    </Typography>
                </DialogContent>


                <DialogActions className="dialog-actionbutton-assignTeamLeader">
                    <button className="cancel-button-assignTeamLeader" onClick={() => closeRecruiterReassignModel()}>
                        Cancel
                    </button>
                    {userType !== "Recruiter" && (
                        <button
                            className={isUserDataUpdating ? 'updateButton-assignTeamLeader_disabled' : 'updateButton-assignTeamLeader'}
                            onClick={(e) => handleReassignment()}
                            disabled={isUserDataUpdating}
                        >
                            <span>Save</span>
                            {isUserDataUpdating ? (<CircularProgress className="circularProgress_updateButton" />) : null}
                        </button>)}
                </DialogActions>

            </Dialog>


            <NotificationContainer />
        </>
    )
})

export default RecruiterRequisitionAssignment