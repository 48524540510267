import { CircularProgress, DialogActions, Paper, TextField } from '@material-ui/core'
import { Autocomplete } from '@material-ui/lab'
import { useEffect, useState } from 'react'
import { codeTofield, renderField } from '../GlobalAssistingFunctions/GlobalAssistingFunctions';
import getDynamicFieldsByStatus from '../../apis/getAllDynamicStatusFields/getDynamicFieldsByStatus';
import { NotificationManager } from "react-notifications";
import updateCandidateRequisition from '../../apis/updateCandidateRequisition/updateCandidateRequisition';
import { updateEventStatus, verifyForm } from '../AcitivityModal/assistingFunctions';
import { DateTime } from "luxon";
import EditSharpIcon from '@material-ui/icons/EditSharp';
import { candidateRequisitionStatusCode } from "../../Config.json"
import { candidatefieldsPut } from '../../apis/candidateField/candidateField';
import FormModel from '../formModel/FormModel';


const UpdateActivity = ({ classes, canReqData, selectedEventIndex, closeEdit, reqDetails, setUpdate, user, userRecruitmentpartnerId, moveCandidate = () => { }, email, allRequisitionData }) => {


    const [requiredFields, setRequiredfields] = useState([])
    const requiredFormFields = ["currentStatus", "gender", "RL", "TL"]
    const [formData, setFormData] = useState({
        TL: "",
        RL: "",
        gender: "",
        currentStatus: "",
        id: "",
        update: false
    });
    const [selectedEvent, setSelectedEvent] = useState()
    const [loader, setLoader] = useState({ loading: true, saving: false, next: false })
    const statusCode=canReqData?.eventStatus?.at(selectedEventIndex)?.candidateStatusCode
    
    // console.log(statusCode)

    // fetch list of fields that are present for the status code
    const getRequiredFields = async (candidateStatusCode) => {
        
        setLoader(elem => ({ ...elem, loading: true }))

        let resp = ""
        if (candidateStatusCode && reqDetails?.placementType)
            resp = await getDynamicFieldsByStatus(candidateStatusCode, reqDetails?.placementType)


        if (resp?.status === 200 && resp?.data)
            setRequiredfields(resp.data)
        setLoader(elem => ({ ...elem, loading: false }))
    }


    // set the selected event in selectedEvent  everytime different event is selected and also get the list of fields to be rendered for the
    // selected event

    useEffect(() => {
        if (canReqData?.eventStatus?.at(selectedEventIndex)?.candidateStatusCode) {

            let event = canReqData.eventStatus.at(selectedEventIndex)
            setSelectedEvent(event?.activityLogs?.at(0))
            getRequiredFields(event.candidateStatusCode)
        }
    }, [selectedEventIndex, canReqData?.eventStatus, reqDetails])

    // submitting the updates of event
    const submitCandReq = async (payload) => {
        setLoader(elem => ({ ...elem, saving: true }))
        try {
            let serverResp = await updateCandidateRequisition(payload)
            if (serverResp.status === 200) {
                let code = canReqData?.eventStatus?.at(selectedEventIndex)?.candidateStatusCode

                if (canReqData?.status?.candidateStatusCode === code)
                    moveCandidate(serverResp?.data?.candidateRequisition, email, code, code, false)
                NotificationManager.success("Candidate submission is successful", "Success", 4000);
            }
            else throw new Error("Failed to save")
            closeEdit()
        }
        catch (err) {
            // console.log(err.message)
            NotificationManager.error(err?.message, "Error", 4000);
        }
        setLoader(elem => ({ ...elem, saving: false }))
    }

    const updateSubmitDataState = (field, value) => {
        setSelectedEvent((elem) => { return { ...elem, [field]: value, updates: true, displayDate: DateTime.now().toISODate() } })
    }

    const submitFormData= async (formPayload)=>{
        setLoader(elem => ({ ...elem, saving: true }))
       
        
        
        const response = await candidatefieldsPut({ ...formPayload, candidateRequisitionId:canReqData?._id})

        if (response.status === 200) {
            NotificationManager.success(
            `${response?.data?.message}`,
            "Success",
            2500);
            closeEdit()
        }
        else NotificationManager.error(`${response?.data?.message}`, "Error", 4000);
        setLoader(elem => ({ ...elem, saving: false }))

    }
    
    const submitter=()=>{
        
        const formPayload = verifyForm(formData,requiredFormFields)
        
        if (!formPayload) {
            setLoader(elem => ({ ...elem, next: true }))
            setLoader(elem => ({ ...elem, saving: false }))
            return
        }
        
        let payload
        try {
            let requisitionData = allRequisitionData?.find(elem => elem?.requisitionId === canReqData?.requisitionId)

            let data = updateEventStatus({ ...selectedEvent, 'submissionType': selectedEvent?.submissionStatus }, user, requiredFields, setLoader)

            payload = {
                data,
                created: false,
                updated: true,
                resumeUpdate: false,
                id: canReqData?._id,
                status: { candidateStatusCode: canReqData?.eventStatus?.at(selectedEventIndex)?.candidateStatusCode },
                resumeLink: canReqData?.resumeLink,
                recruitmentpartnerId: userRecruitmentpartnerId,
                senderId: user,
                previoussubmissionType: canReqData?.submissionType,
                requisitionTeamLeaderUserId: requisitionData?.team?.teamLeaderId || "",
                reqStatusAll: {
                    "reqLead": { "statusCode": requisitionData?.requisitionDetails?.status.statusCode, "subCode": requisitionData?.requisitionDetails?.status.subCode },
                    "reqTl": { "statusCode": requisitionData?.teamleaderrequisitions?.status.statusCode, "subCode": requisitionData?.teamleaderrequisitions?.status.subCode }, "reqRec": { "statusCode": requisitionData?.status.statusCode, "subCode": requisitionData?.status.subCode }
                },
                requisitionRecLeaderUserId: requisitionData?.requisitionDetails?.assignedTo || "",
                requisitionRecUserId: requisitionData?.recruiterId || "",
                teamId: requisitionData?.team?.teamId || "",
                requisitionDetailId: requisitionData?.requisitionDetailId || "",
                requisitionLeaderId: requisitionData?.requisitionLeaderId || "",
                requisitionRecruiterId: requisitionData?._id || "",
                requisitionTeamLeaderId: requisitionData?.requisitionTeamLeaderId || "",
            }
        }
        catch (err) {
            NotificationManager.error(err?.message, "Error", 4000);
            return
        }

        if(selectedEvent?.updates)
            submitCandReq(payload)

        if(formData?.update)
            submitFormData(formPayload)
    }
    
    return (

        <>
            {
                loader?.loading ? <div className='loaderForEdit'><CircularProgress /></div> :
                    <>
                        <div className="updateActivityformWrapper">
                            <span className='spaceOut mt_10'>Reference ID *</span>

                            <Autocomplete
                                id="free-solo-2-demo"
                                classes={{
                                    root: classes.autocomplete,
                                    inputRoot: classes.inputRoot,
                                    popper: classes.popper,
                                }}
                                freeSolo
                                value={reqDetails?.reqreferenceId || " "}
                                disabled={true}
                                size="small"
                                filterSelectedOptions
                                PaperComponent={({ children }) => (
                                    <Paper
                                        style={{ fontSize: "12px", fontFamily: 'Segoe UI' }}
                                    >
                                        {children}
                                    </Paper>
                                )}
                                renderInput={(params) =>
                                    <TextField
                                        {...params}
                                        placeholder=''
                                        variant="outlined"
                                    />}
                            />


                            <span className='spaceOut'>Status *</span>
                            <Autocomplete
                                key={selectedEvent?.candidateStatusCode}
                                id="free-solo-2-demo"
                                classes={{
                                    root: classes.autocomplete,
                                    inputRoot: classes.inputRoot,
                                    popper: classes.popper,
                                }}
                                freeSolo
                                value={canReqData?.eventStatus?.at(selectedEventIndex)}
                                getOptionLabel={(option) => codeTofield(option?.candidateStatusCode)}
                                disabled={true}
                                size="small"
                                filterSelectedOptions
                                PaperComponent={({ children }) => (
                                    <Paper
                                        style={{ fontSize: "12px", fontFamily: 'Segoe UI' }}
                                    >
                                        {children}
                                    </Paper>
                                )}
                                renderInput={(params) =>
                                    <TextField
                                        {...params}
                                        placeholder=''
                                        variant="outlined"
                                    />}
                            />


                            {selectedEvent && !loader.next ?
                                <>
                                    {requiredFields?.submissionType?.length > 0 && (
                                        <>
                                            <span className='spaceOut'>Submission Type *</span>
                                            <Autocomplete
                                                id="submission-type-autocomplete"
                                                freeSolo
                                                // disabled={true}
                                                options={requiredFields.submissionType || []}
                                                getOptionLabel={(option) => option?.type || ""}
                                                size="small"
                                                value={{ type: selectedEvent?.submissionStatus }}
                                                onChange={(e, value) => {
                                                    updateSubmitDataState('submissionStatus', value?.type);
                                                }}
                                                PaperComponent={({ children }) => (
                                                    <Paper style={{ fontSize: "16px", fontFamily: 'Segoe UI' }}>
                                                        {children}
                                                    </Paper>
                                                )}
                                                renderInput={(params) => (
                                                    <TextField {...params} placeholder='Select Submission Type' variant="outlined" />
                                                )}
                                                className={classes.autocomplete}
                                            />
                                        </>
                                    )}

                                    {/* Check if contractualType is present in currentStatus */}
                                    {requiredFields?.contractualType?.length > 0 &&
                                        <>
                                            <span className='spaceOut'>Contractual Type *</span>
                                            <Autocomplete
                                                id="submission-type-autocomplete"
                                                options={requiredFields.contractualType || []} // Provide the submissionType options
                                                getOptionLabel={(option) => option} // Use the type for display
                                                size="small"
                                                value={selectedEvent?.contractualType}
                                                onChange={(e, value) => {
                                                    updateSubmitDataState('contractualType', value);
                                                }}
                                                PaperComponent={({ children }) => (
                                                    <Paper style={{ fontSize: "16px", fontFamily: 'Segoe UI' }}>
                                                        {children}
                                                    </Paper>
                                                )}
                                                renderInput={(params) => (
                                                    <TextField {...params} placeholder='Select Contractual Type' variant="outlined" />
                                                )}
                                                className={classes.autocomplete}
                                            />
                                        </>
                                    }

                                    {
                                        // renderFields are the required fields for the given status code and renderField function returns
                                        // data fields for the form  
                                        requiredFields?.fields?.map((field) => (
                                            <div key={field.name} className='ActivityDynamicFields' >
                                                <span className='spaceOut'>
                                                    {field.name}{' *'}
                                                </span>
                                                {renderField(field, selectedEvent, updateSubmitDataState)}
                                            </div>
                                        ))
                                    }
                                </>

                                :
                                loader.next && formData && <FormModel
                                    formData={formData}
                                    classes={classes}
                                    candidateReqId={canReqData?._id}
                                    orgId={userRecruitmentpartnerId}
                                    setFormData={setFormData}
                                    loader={loader}
                                    setLoader={setLoader}
                                />
                            }



                        </div>
                        <DialogActions className="dialog-actionbutton-division">

                            <button
                                type="button"
                                className="cancel-button-division"
                                onClick={closeEdit}
                            >
                                Cancel
                            </button>
                            {!loader.loading && canReqData?._id && statusCode === candidateRequisitionStatusCode?.SUBMITTED &&
                                <button
                                    disabled={loader?.saving || loader?.loading}
                                    type="button"
                                    className="updateButton-division editButtonAcitivity"
                                    onClick={() => {
                                        setLoader((elem) => ({ ...elem, next: !elem.next }))
                                    }}
                                >
                                    <div> {loader.next ? "Fields" : "Form"}</div>
                                </button>
                            }
                            <button
                                type="button"
                                className="cancel-button-division"
                                onClick={() => setUpdate(elem => !elem)}
                            >
                                Back
                            </button>
                            <button
                                type="button"
                                className='updateButton-division'
                                key={(selectedEvent?.updates && formData?.update)}
                                onClick={submitter
                                }
                                disabled={loader?.saving || loader?.loading || !(selectedEvent?.updates || formData?.update)}
                            >
                                update

                                {loader?.saving && <CircularProgress className='spaceLeft' />}
                            </button>

                        </DialogActions>

                    </>
            }
        </>

    )
}

export default UpdateActivity
