import axios from "axios";
const jsonConfig = require("../../Config.json");

const allRoles = async (payload) => {
  let allResponse = {};
  try {
    const allRoleResponse = await axios.get(
      jsonConfig.apiUrl + "roles",
      {
        headers: {
          "x-functions-key": jsonConfig.key,
        },
      }
    );

    allResponse = allRoleResponse;
    return Promise.resolve(allResponse);
  } catch (error) {
    console.log("errrrrr", error);
    return Promise.reject(error);
  }
};

export default allRoles;