import axios from "axios";
import * as fflate from 'fflate';
const jsonConfig = require("../../Config.json");


export const candidatefieldsGet = async (id) => {
  try {
    const resp = await axios.get(
      jsonConfig.apiUrl +
        "candidateRquisitionInfo?" +
        "candidateRequisitionId=" +
        id,
      {
        headers: {
          "x-functions-key": jsonConfig.key,
        },
      }
    );
    let decompressedObject;
    if (resp?.data && resp?.data?.compressedBase64String) {
        const strData = atob(resp.data.compressedBase64String);
        
      const charData = strData.split("").map((x) => {
        return x.charCodeAt(0);
      });
      const binData = new Uint8Array(charData);
      let apiDataUnCompressed = fflate.unzlibSync(binData);
      let apiDataUnCompressedString = fflate.strFromU8(apiDataUnCompressed);
      decompressedObject = JSON.parse(apiDataUnCompressedString);
    }

    return decompressedObject
  } catch (err) {
    // console.log(err);
    return false;
  }
};


export const candidatefieldsPost = async (payload,id) => {
  try {
      const resp = await axios.post(
          jsonConfig.apiUrl + "candidateRquisitionInfo?" +'candidateRequisitionId='+id,
          payload,
          {
              headers: {
                  "x-functions-key": jsonConfig.key,
              },
          });
     
      return Promise.resolve(resp)
  }
  catch (err) {
      return Promise.reject(err);
  }
}



export const candidatefieldsPut = async (payload) => {
  try {
      const resp = await axios.put(
          jsonConfig.apiUrl + "candidateRquisitionInfo",
          payload,
          {
              headers: {
                  "x-functions-key": jsonConfig.key,
              },
          });
      
      return Promise.resolve(resp)
  }
  catch (err) {
      return Promise.reject(err);
  }
}