/* eslint-disable react/react-in-jsx-scope */
import React, { useContext, useEffect, useState } from 'react';
import { Route, Redirect } from 'react-router-dom';
import userSessionLogs from './apis/userSessionLogs';
import { UserTypeContext } from './AppRoute';
import customLogoutResponse from './apis/logout/customLogout';
const ProtectedRoute = ({ component: Component, ...rest }) => {
  const { userRecruitmentpartnerId, userIdType } = useContext(UserTypeContext); 
  const [isUserActive, setIsUserActive] = React.useState(false);
  const [redirect, setRedirect] = useState(false);
  let userId = localStorage.getItem('userID') || userIdType
  const sendActivityPing = async () => {
    
    try {
      
      const res = await userSessionLogs(userId, localStorage.getItem("token"))
      if (res.status === 401 && res.data.message === 'Token expired') {
        // Execute custom logout response
        await customLogoutResponse({
          userId,
          recruitementPartnerId: userRecruitmentpartnerId,
        });
        // Clear local storage
        localStorage.clear();
        setRedirect(true)
      }

    } catch (error) {
      console.error('Error in sendActivityPing:', error);
      // Handle other errors (e.g., network issues)
      if (error.response?.status === 401 && error.response?.data?.message === 'Token expired') {
        // Handle token expiration case here as well
        await customLogoutResponse({
          userId,
          recruitementPartnerId: userRecruitmentpartnerId,
        });
        localStorage.clear();
        setRedirect(true)

      }
    }
  }


  React.useEffect(() => {
    const handleUserActivity = () => {
      setIsUserActive(true);
    };

    const events = ['click', 'mousemove', 'keydown', 'mousedown', 'touchstart'];
    events.forEach(event => {
      window.addEventListener(event, handleUserActivity);
    });

    const intervalId = setInterval(() => {
      if (isUserActive) {
        sendActivityPing();
        setIsUserActive(false);
      }
    }, 60000); // 1 minute interval

    return () => {
      events.forEach(event => {
        window.removeEventListener(event, handleUserActivity);
      });
      clearInterval(intervalId);
    };
  }, [isUserActive]);

  const events = ['click', 'mousemove', 'keydown', 'mousedown', 'touchstart'];

  let inactivityTimer;


  const resetTimer = () => {
    clearTimeout(inactivityTimer);

    inactivityTimer = setTimeout(async () => {
      console.log('No activity for 5 minutes. Clearing local storage.');

      try {
        // Perform the logout operation
        await customLogoutResponse({
          userId,
          recruitementPartnerId: userRecruitmentpartnerId,
        });
      } catch (error) {
        console.error('Error during customLogoutResponse:', error);
      }

      // Clear local storage and trigger redirect
      localStorage.clear();
      setRedirect(true); // Trigger the redirect
    }, 15 * 60 * 1000); // 15 minutes
  };
  useEffect(() => {
    // Attach event listeners
    events.forEach((event) => window.addEventListener(event, resetTimer));

    // Start the timer when the component mounts
    resetTimer();

    return () => {
      // Clean up: Remove event listeners and clear the timer
      events.forEach((event) => window.removeEventListener(event, resetTimer));
      clearTimeout(inactivityTimer);
    };
  }, []);

  if (redirect) {
    return <Redirect to={{ pathname: '/signIn' }} />;
  }






  const getToken = localStorage.getItem('token');

  return (
    <Route {...rest} render={(props) => (
      !!getToken ?
        <Component {...props} /> : <Redirect to={{ pathname: '/signIn', state: { from: props.location } }} />
    )} />
  )
}

export default ProtectedRoute
