import { useState, useEffect, useCallback, useContext } from "react";
import "./activity.css";
import Header from "../../components/headerAdmin/Header";
import pdfIcon from "../../pages/myTalentCommunity/icons/pdf.svg"
import docIcon from "../../pages/myTalentCommunity/icons/doc.png";
import { Backdrop, Button, Grid, CircularProgress, Typography } from '@material-ui/core';
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import "react-datepicker/dist/react-datepicker.css";
import { useHistory } from "react-router";
import { useLocation } from "react-router-dom";
import { makeStyles } from '@material-ui/core/styles';
import Footer from "../../components/adminFooter/Footer";
import { Skeleton } from '@material-ui/lab';
import EditIcon from "../../components/basicInfoForm/images/edit-icon.png"
import getAllRequitionForCandi from "../../apis/getAllRequitionForCandidates/getAllRequitionForCandidates";
import { Grid as GridDiv, GridColumn, GridNoRecords } from "@progress/kendo-react-grid";
import RecentActivities from "../../components/RecentActivities/RecentActivities.js";
import ExcelImg from "../../components/basicInfoForm/images/excelIcon.jpg";
import {
    NotificationContainer, NotificationManager
} from "react-notifications";
import debounce from 'lodash/debounce';
import configJSON from "../../Config.json";
import * as XLSX from 'xlsx';
import EditActivity from "../../components/EditActivity/EditActivity";
import ActivityModal from "../../components/AcitivityModal/AcitivityModal";
import { UserTypeContext } from "../../AppRoute";
import { convertDate } from "../../components/AcitivityModal/assistingFunctions";
import { candidateRequisitionStatusCode } from "../../Config.json"
import { camelCase, codeTofield } from "../../components/GlobalAssistingFunctions/GlobalAssistingFunctions";
import { filterBy } from "@progress/kendo-data-query";

const useStyles = makeStyles((theme) => ({
    search: {
        position: 'relative',
        backgroundColor: "#d1e8ff",
        marginLeft: 0,
        width: '70%',
        marginTop: '4px',
        borderRadius: "5px",
        height: "25px",
    },
    buttonForSupport: {
        justifyContent: "flex-end !important",
        padding: "5px 42px 20px !important",
    },
    autocomplete: {
        // Your custom styles for the Autocomplete component
        // For example:
        // width: '300px',
        // height:"10px"
    },
    searchIcon: {
        //   padding: theme.spacing(0, 2),

        height: '100%',
        position: 'absolute',
        pointerEvents: 'none',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
    inputRoot: {
        //   color: 'inherit',

    },
    profileButton: {
        color: '#fff',
        backgroundColor: '#1885e6',
        width: '70px',
        '&:hover': {
            backgroundColor: '#1885e6',
            opacity: '0.9'
        }
    },
    inputInput: {
        padding: theme.spacing(0, 4),
        // vertical padding + font size from searchIcon
        paddingLeft: "0px",
        transition: theme.transitions.create('width'),
        width: '100%',
        //   backgroundColor:"#d1e8ff",
        [theme.breakpoints.up('md')]: {
            width: '20ch',
        },
    },
    title: {
        backgroundImage: "linear-gradient(90deg, rgba(49, 179, 248, 1) 0%, rgba(29, 105, 146, 1) 100%);!important"
    },
    odd: {
        background: '#F6F6F6 !important'
    },
    even: {

        background: '#fffff !important'
    },
    btnCancel: {
        width: "120px",
        height: "32px",
        backgroundColor: "#FFFFFF",
        color: "#1885E6",
        border: "1px solid #1885E6",
        paddingTop: '5px',
        fontSize: '15px',
        borderRadius: "3px",
        "&:hover": {
            opacity: "0.8",
            backgroundColor: "#D1E8FF"
        }
    },
    btnSave: {
        width: "120px",
        height: "32px",
        backgroundColor: "#1885E6",
        fontSize: '15px',
        color: "#FFFFFF",
        border: "1px solid #1885E6",
        paddingTop: '5px',
        borderRadius: "3px",
        "&:hover": {
            opacity: "0.8"
        }
    },
    btnExcel: {
        display: "flex",
        justifyContent: "space-between"
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
    waitingText: {
        marginLeft: '5px'
    },

}));


const Activity = (props) => {
    const location = useLocation();
    const history = useHistory();
    const classes = useStyles();
    const { userType, userRecords } = useContext(UserTypeContext);
    const id = userType === 'Admin' ? props?.location?.state?.id : userRecords?.id;
    const userRecruitmentpartnerId = userType === 'Admin' ? props?.location?.state?.userRecruitmentpartnerId : userRecords?.recruitmentpartnerId;
    const userRole = userType === 'Admin' ? props?.location?.state?.userRole : userRecords?.role?.roleName;
    const userRecrodData = userType === 'Admin' ? props?.location?.state?.userRecords : userRecords;


    const [edit, setEdit] = useState(false)
    const [isExporting, setIsExporting] = useState(false);
    const [isActivity, setisActivity] = useState(false)
    let requi = location?.state?.requisitionId
    const [email, setEmail] = useState("")
    const [isSearching, setIsSearching] = useState(true)
    const [loading, setLoading] = useState(false);

    const [selectedCandReq, setSelectedCandReq] = useState()
    const [page, setPage] = useState({ skip: 0, take: 10 });
    const [reqId, setReqId] = useState(location?.state?.requisitionId)
    const [filter, setFilter] = useState(null);
    const [activCount, setactivCount] = useState({});
    const [dataResult, setDataResult] = useState(null);
    const [counts, setCounts] = useState();
    const [curStatus, setCurStatus] = useState(location?.state?.candidateState || "CRS01")
    const [allRequisitionData, setAllRequisitionData] = useState([]);
    const [recId,setRecId]=useState("")    
    const onFilterChange = (event) => {
        setFilter(event.filter);
    };

    const debouncedSearch = useCallback(
        debounce(async (id, userType, requi) => {
            setLoading(true);
            let search;
            let user = userType === 'Admin' ? userRole : userType

            let fields = {
                "MIS Operator": "orgId",
                "Recruiter": "recruiterId",
                "Recruitment Leader": "recruitmentLeaderId",
                "Organization Admin": "orgId",
                "Team Leader": "teamLeaderId",
            }
            let payload_data = {
                "candidateStatusCode": Object.values(candidateRequisitionStatusCode),
                "requisitionId": requi,
                "searchData": {},
                "recruitmentpartnerId": userRecruitmentpartnerId,
                "userRecruitmentpartnerId": userRecruitmentpartnerId,
                "userId": userRecords?.id
            }

            if (userType === "Organization Admin" || userType === "MIS Operator" || (userType === "Admin" && userRole === "Organization Admin") || (userType === "Admin" && userRole === "MIS Operator")) {
                payload_data = { ...payload_data, [fields[user]]: userRecruitmentpartnerId }
                search = await getAllRequitionForCandi(payload_data);
            }
            else {
                payload_data = { ...payload_data, [fields[user]]: id }
                search = await getAllRequitionForCandi(payload_data);
            }

            let allCandidates = {}

            Object.values(candidateRequisitionStatusCode).forEach(elem => {
                allCandidates[elem] = []
            })

            if (search?.data?.data?.length > 0) {
                let data = search.data.data

                data?.forEach((elem) => {
                    let key = elem?.status?.candidateStatusCode
                    allCandidates[key].push(elem)
                    // console.log(key,elem)
                })
                allCandidates["all"] = data
                // for (let i = 0; i < 5; i++)
                //     allCandidates["all"].push(...allCandidates["all"])
                // allCandidates["all"].push(...allCandidates["CRS01"])
                // allCandidates["all"].push(...allCandidates["CRS01"])
                // allCandidates["all"].push(...allCandidates["CRS01"])
                // allCandidates["CRS01"].push(...allCandidates["all"])
                setDataResult(allCandidates);
            }
            else {
                setDataResult(null);
            }
            setLoading(false);
            setIsSearching(false);

        }, 1000),
        []
    );

    useEffect(() => {
        setIsSearching(true);
        setDataResult(null);
        debouncedSearch(id, userType, requi)
    }, [])




    const moveCandidate = (payload, email, from, to, del = false) => {
        let data = { ...dataResult };

        Object.keys(candidateRequisitionStatusCode).forEach((elem) => {
            let key = camelCase(elem)
            if (from === key)
                from = candidateRequisitionStatusCode[elem]
            if (to === key)
                to = candidateRequisitionStatusCode[elem]
        })
        let candidate = data[from]?.filter(elem => elem.email === email)?.at(0)
        let count = { ...activCount }
        // console.log(candidate)
        for (let field of Object.keys(payload)) {
            if (field !== "salary")
                candidate[field] = payload[field]
        }

        candidate.updated_at = convertDate(candidate?.updated_at)
        candidate.eventDate = convertDate(candidate?.eventDate)


        data[from] = data[from]?.filter(elem => elem.email !== email)
        data["all"] = data["all"]?.filter(elem => elem.email !== email)

        if (!del) {
            data["all"]?.push(candidate)
            data[to]?.push(candidate)
            count[to]++
        }
        count[from]--
        // console.log(count)
        setactivCount(count)
        setDataResult(data)
    }

    const handleCandidateLink_candidatesearch = (props) => {
        return (
            <td align="center" className="activityInviteBtnAllignment cp">
                <Button
                    size="small"
                    variant="contained"
                    disabled={userType === "Admin" ? true : false}
                    className={classes.profileButton}
                    onClick={(e) => handleGotoProfile(props?.dataItem)}
                >
                    Action
                </Button>
            </td>
        );
    };



    const handleGotoProfile = (data) => {
        setSelectedCandReq(prevState => ({
            ...prevState,  // Spread the previous state object to retain other properties
            _id: data?._id || "",
            referenceId: data?.requisitiondetails?.reqreferenceId || "",
            requisitionId: data?.requisitionId || "",
            requisitionDetailId: data?.requisitionDetailId || "",
            requisitionLeaderId: data?.requisitionLeaderId || "",
            requisitionRecruiterId: data?.requisitionRecruiterId || "",
            name: data?.name || "",
            email: data?.email || "",
            phone: data?.phone || "",
            eventStatus: data?.eventStatus || [],
            resumeLink: data?.resumeLink || "",
            status: data?.status,
            notes: data?.notes || "",
            isActive: data?.isActive,
            noOfOpenings: data?.recruiterrequisitions?.no_of_positions || 0,
            totalNoOfOpenings: data?.requisitiondetails?.openings || 0
        }));
        setEmail(data?.email)
        setisActivity(true)
    }



    const goToDashboard = () => {
        history.push({
            pathname: "/dashboard",
            state: { id: id, recruitmentPartnerId: userRecruitmentpartnerId, userType: userType, userRecords: userRecrodData, userRole: userRole },
        });
    }




    const downloadResume = async (event, url) => {
        window.open(url, "_blank");
    };

    const getSkeleton_candidateSearch = () => {
        let arr = []
        for (let i = 0; i <= 10; i++) {
            arr.push(<Skeleton key={"AcitivityPage" + i} className={i % 2 === 0 ? classes.odd : classes.even} variant="rect" width="100%" height="50px" />)
        }
        return arr;
    }



    const callApiToGenerateExcel = async () => {
        setIsExporting(true);

        if (!dataResult || !dataResult[curStatus]) {
            NotificationManager.error("No data available", "Error", 2500);
            setIsExporting(false);
            return;
        }

        let data = filterBy(dataResult[curStatus], filter)

        if (!data || !(data?.length > 0)) {
            NotificationManager.error("No data available", "Error", 2500);
            setIsExporting(false);
            return;
        }
        let eventTitle = title[curStatus] || "Event Date";
        const exportData = data.map(candidate => ({
            name: candidate.name,
            email: candidate.email,
            Phone: candidate.Phone,
            "Recruiter Name": candidate.recruiterData.displayName,
            "Updated On": candidate.updated_at,
            [eventTitle]: candidate.eventDate,
        }));

        const ws = XLSX.utils.json_to_sheet(exportData);

        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Data");
        XLSX.writeFile(wb, "report details.xlsx");

        setIsExporting(false);
    };
    const title = {
        "CRS03": "Interview Date",
        "CRS010": "Interview Date",
        "CRS012": "Interview Date",
        "CRS04": "Starting Date",
        "CRS06": "Started On",
        "CRS07": "Dropout Date",
        "CRS08": "Withdraw Date",
        "all": "Date"
    }

    const columns = [
        { field: "name", title: "Name", width: "170px", className: classes.fontRoboto },
        { field: "email", title: "Email", width: "250px", className: classes.fontRoboto },
        { field: "phone", title: "Phone", width: "180px", sortable: false, className: classes.fontRoboto },
        {
            field: "recruiterData.displayName", title: "Recruiter Name", width: "170px",
            className: classes.fontRoboto, filterable: false
        },
        { className: classes.fontRoboto, field: "updated_at", filterable: false, title: "Updated On", width: "220px" },
        { className: classes.fontRoboto, field: "eventDate", title: title[curStatus], width: "190px" }
    ]


    const handlePageChange = (e) => {
        if (loading)
            return
        setPage({
            skip: e.page.skip,
            take: e.page.take
        });
    };
    return (
        <>
            <Backdrop
                className={classes.backdrop}
                open={isExporting}
            >
                <CircularProgress color="inherit" />
                <Typography className={classes.waitingText}> Please wait ...</Typography>
            </Backdrop>


            <div className='talentCommunityMainContainer'>
                <div className='talentCommunityTopContainer'>
                    <Grid container>
                        <Grid item xs={12}>
                            <Header />
                        </Grid>
                    </Grid>
                    <div className="mainDiv_advanceSearch">
                        <div className="">

                            {
                                <div className="titlesofCommunity">
                                    Stage : {codeTofield(curStatus) || "ALL"}
                                </div>
                            }

                            {/* </div> */}

                        </div>
                        <div className={classes.btnExcel}>
                            <div className="goBackToRecruiterDashboard mt_10 " onClick={(e) => goToDashboard()}>

                                <ArrowBackIcon />  &nbsp;Go Back to Dashboard
                            </div>
                            <div>
                                <button className='excelButton'
                                    onClick={callApiToGenerateExcel}
                                >
                                    <img src={ExcelImg} className='excelImg_advanceSearch' alt="" /><span>Export</span></button>
                            </div>
                        </div>
                        <div className="interview_mainCont">
                            <div className="activity_topCon">
                                <div className="pt_20 KendoContainer_Activity">
                                    <GridDiv
                                        filterable={true}
                                        sortable={true}
                                        resizable={true}
                                        reorderable={true}
                                        pageable={{
                                            pageSizes: [10, 20, 50, 100],
                                            info: true,
                                            previousNext: true,
                                            buttonCount: 10
                                        }}
                                        skip={page.skip}
                                        take={page.take}
                                        // filterOperators={filterOperators}
                                        filter={filter}
                                        onFilterChange={onFilterChange}
                                        // onDataStateChange={dataStateChange}
                                        data={dataResult && dataResult[curStatus]?.length > 0 ? filterBy(dataResult[curStatus], filter)?.slice(page?.skip, page?.take + page?.skip) : null}
                                        onPageChange={handlePageChange}
                                        total={dataResult ? filterBy(dataResult[curStatus], filter)?.length || 0 : 0}
                                        // total={20}
                                        className='kendo_Activity'
                                    >
                                        <GridNoRecords>
                                            {isSearching ? getSkeleton_candidateSearch() : "No results found !"}
                                        </GridNoRecords>
                                        {
                                            (userType === "Recruitment Leader" || userType === "Admin" || userType === "Organization Admin" || userType === "MIS Operator" || userType === "Team Leader" ||
                                                userType === "Recruiter") &&
                                            <GridColumn
                                                width="150px"
                                                filterable={false}
                                                title="Action"
                                                cell={handleCandidateLink_candidatesearch}
                                            />
                                        }
                                        <GridColumn
                                            field="resumeLink"
                                            filterable={false}
                                            title="Document"
                                            width="130px"

                                            cell={(event) => {
                                                return (
                                                    <td>


                                                        <div
                                                            className="activityInviteBtnAllignment cp"
                                                            onClick={(e) =>
                                                                downloadResume(
                                                                    e,
                                                                    event?.dataItem?.resumeLink[event?.dataItem?.resumeLink?.length - 1]?.link // Getting the link from array of resumeLink
                                                                )
                                                            }
                                                        >
                                                            {/* Rendering the icon based on the file extension on the kendo Tbale */}
                                                            {
                                                                event?.dataItem?.resumeLink[event?.dataItem?.resumeLink.length - 1]?.link?.slice(-4) === "docx" || event?.dataItem?.resumeLink[event?.dataItem?.resumeLink.length - 1]?.link?.slice(-3) === "doc" ? (
                                                                    <>
                                                                        <img src={docIcon} alt="doc-icon" />
                                                                    </>
                                                                ) : event?.dataItem?.resumeLink[event?.dataItem?.resumeLink.length - 1]?.link?.slice(-3) === "pdf" ? (
                                                                    <>
                                                                        <img src={pdfIcon} width="39px" alt="pdf-icon" />
                                                                    </>
                                                                ) : (
                                                                    "N/A"
                                                                )
                                                            }
                                                        </div>

                                                    </td>
                                                );
                                            }}
                                        />
                                        {
                                            columns && columns?.map((elem, i) => elem?.title ? <GridColumn key={"activityGrid" + i} {...elem} /> : null)
                                        }


                                        {curStatus === "All Candidate" &&

                                            (
                                                <GridColumn
                                                    className={classes.fontRoboto}
                                                    field="candidateStatus.name"
                                                    filterable={false}
                                                    title="Stage"
                                                    width="160px"
                                                />
                                            )
                                        }
                                        {curStatus === "All Candidate" &&
                                            <GridColumn
                                                className={classes.fontRoboto}
                                                field="eventDate"
                                                filter={"date"}
                                                title="Date"
                                                width="190px"
                                            />
                                        }

                                    </GridDiv>

                                </div>


                                <div className="activity_rightCon">
                                    <RecentActivities activity={true} requisitionId={requi} idContextTest={id} userType={userType} userRecords={userRecrodData} userRole={userRole} userRecruitmentpartnerId={userRecruitmentpartnerId} isSearching={isSearching}

                                        curStatus={curStatus}
                                        setCurStatus={setCurStatus}
                                        // statusCodes={statusCodes}
                                        setCounts={setCounts}
                                        counts={counts}
                                        activCount={activCount}
                                        setactivCount={setactivCount}
                                    />

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="activity_footer">
                    <Footer />
                </div>

            </div>

            {/* Action Card */}
            {isActivity && <ActivityModal
                setRecId={setRecId}
                isActivity={isActivity}
                props={{ requisitionIdVal: location.state.requisitionId }}
                configJSON={configJSON}
                setisActivity={setisActivity}
                row_DataItem={selectedCandReq}
                page={"Activity"}
                classes={classes}
                setEdit={setEdit}
                reqId={reqId}
                email={email}
                setReqId={setReqId}
                userRecruitmentpartnerId={userRecruitmentpartnerId}
                moveCandidate={moveCandidate}
                setAllRequisitionData={setAllRequisitionData}
                recId={recId}
            />
            }
          
            <EditActivity edit={edit} classes={classes} EditIcon={EditIcon} setEdit={setEdit}
                setisActivity={setisActivity}
                selectedCandReq={selectedCandReq} reqId={reqId}
                email={email} props={props}
                moveCandidate={moveCandidate}
                allRequisitionData={allRequisitionData}
            />
            <NotificationContainer />
        </>
    )
}

export default Activity;