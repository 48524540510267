import { ArrowBack } from "@material-ui/icons"
import "./RequisitionReportDetails.css"
import { Grid, GridColumn, GridNoRecords } from "@progress/kendo-react-grid"
import { useHistory, useLocation } from 'react-router-dom';
import ExcelImg from "../../components/basicInfoForm/images/excelIcon.jpg"
import { Backdrop, Box, Button, CircularProgress, makeStyles, TextField, Typography } from '@material-ui/core';
import * as XLSX from "xlsx";
import { useContext, useEffect, useState } from "react"
import Header from "../../components/headerAdmin/Header";
import Footer from "../../components/adminFooter/Footer";
import BasicHeader from "../../components/basicHeader/BasicHeader";
import recruiterActivityDetailReports from "../../apis/recruiterActivityDetailReports/recruiterActivityDetailReports";
import { Autocomplete } from "@material-ui/lab";
import { getClientByOrg } from "../../apis/organisationAdminAPI/manageClient";
import getAllDivionByOrg from "../../apis/getAllDivionByOrg/getAllDivisionByOrg";
import { expandableColumns, fixData, jobReportSkeleton } from "./assistingFunctions/assistingFunctions";
import { UserTypeContext } from "../../AppRoute";
import {
    NotificationManager,
} from "react-notifications";



function RequisitionReportDetails({ userType }) {

    const useStyles = makeStyles((theme) => ({
        backdrop: {
            zIndex: theme.zIndex.drawer + 1,
            color: "#fff",
        },
    }));

    const { userRecruitmentpartnerId: orgId, userIdType: userId } = useContext(UserTypeContext)
    const [page, setPage] = useState({ skip: 0, take: 10 });
    const [saving, setSaving] = useState(false);
    const history = useHistory();
    const location = useLocation();
    const [loading, setLoading] = useState(true)
    const [data, setData] = useState(null)
    const [clientsDivisions, setClientsDivisions] = useState({ clients: [], divisions: [], divisionID: {} });
    const [filters, setFilters] = useState({ clientFilter: [], divisionFilter: [] })
    let { recId, startDate, endDate, field } = location.state;
    const classes = useStyles();
    const filterFeilds = ["_id", "requisitionId", "recruiterId", "recruiterDetails"]
    const [totalCount, setTotalCount] = useState(0)



    useEffect(() => {
        const getClientsDivsions = async () => {
            let payload = { recruitmentPartnerId: orgId }
            try {
                let response = await getClientByOrg(payload);
                let clients = response?.data?.data
                setClientsDivisions((elem) => { return { ...elem, clients } })

            } catch (error) {

                setClientsDivisions((elem) => { return { ...elem, clients: [] } })
            }

            payload = { isManageClient: false }
            try {
                let response = await getAllDivionByOrg(orgId, payload);
                let divisions = response?.data?.data
                // .map(elem => elem.divisionName)
                setClientsDivisions((elem) => { return { ...elem, divisions } })

            } catch (error) {
                setClientsDivisions((elem) => { return { ...elem, divisions: [] } })

            }
        }

        getClientsDivsions()

    }, [])


    useEffect(() => {
        getData()
    }, [page])

    const getData = async () => {
        setLoading(true)
        setData(null)

        let requisitionsFilterId = "", candidateFilterId = ""

        if (field === "SC2" || field === "SC1")
            requisitionsFilterId = field
        else
            candidateFilterId = field

        try {
            const resp = await recruiterActivityDetailReports({
                orgId,
                "page": page.skip / page.take + 1,
                "size": page.take,
                "formattedDatestart": startDate.slice(0, startDate.indexOf('T')),
                "formattedDateend": endDate.slice(0, endDate.indexOf('T')),
                "isExport": false,
                userId,
                role: userType,
                "additionalFilter": {
                    "divisionId": filters.divisionFilter,
                    "clientName": filters.clientFilter,
                    recId,
                    requisitionsFilterId,
                    candidateFilterId
                }
            })
            setData(resp?.data)
            setTotalCount(resp.totalCount[0]?.totalCount)

        }
        catch (error) {
            setData(null)
            console.log(error, "error")

        }

        setLoading(false)
    }


    // export data to excel //
    const exportExcel = () => {

        if (data === null || data?.length === 0) {
            NotificationManager.error("No data found", "Error", 2500);
            return ;
        }
        setSaving(true)


        let exportData = data.map((elem) => {

            let name_date = elem["candNames"].map((innerElem) => `name : ${innerElem.consultantName} date : ${innerElem.startDate === "" ? "No date present" : innerElem.startDate}`).join(',  ')

            return { divisionName: elem.divisionName, placementType: elem.placementType, clientName: elem.clientName, "candNames": name_date }
        })
        // console.log(exportData)
        const ws = XLSX.utils.json_to_sheet(exportData);

        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Data");
        XLSX.writeFile(wb, "Recruiter's Activity report details.xlsx");
        setSaving(false)
    }

    const gotoReports = () => {
        history.push("/requisition-reports")
    }

    // change page of displayed data //
    const handlePageChange = (e) => {
        if (loading)
            return
        setPage({
            skip: e.page.skip,
            take: e.page.take
        });
    };

    const changePageSize = (e) => {
        if (loading)
            return
        setPage((elem) => {
            return {
                ...elem,
                take: e.dataState.take
            }
        });
    };

    const handleClientNameChange = (event, clientFilter) => {
        setFilters(elem => { return { ...elem, clientFilter: clientFilter.map((elem) => elem._id) } })
    }
    const handleDivisionChange = (event, divisionFilter) => {
        setFilters(elem => { return { ...elem, divisionFilter: divisionFilter.map((elem) => elem._id) } })
    }

    const handleSearch = async (event) => {

        setPage((prop) => {
            return {
                ...prop,
                skip: 0
            }
        })

    }


    return (
        <>

            {((userType === "Admin") || (userType === "Recruitment Leader") || (userType === "Organization Admin") || (userType === "MIS Operator") || (userType === "Team Leader")) ?
                <>
                    <Backdrop className={classes.backdrop} open={saving}>
                        <CircularProgress color="inherit" />
                        <Typography >
                            {" "}
                            Please wait ...
                        </Typography>
                    </Backdrop>
                    <div className='mainContainer_requisitionReports'>
                        <div className='topContainer_requisitionReports'>
                            <Header />
                            <div className='mainDiv_requisitionReports'>
                                <div className='titleContainer_requisitionReports'>
                                    <span className='title'>Reports</span>
                                </div>
                                <div className='gobackContainer_requisitionReports'>
                                    <div className="goBackToDashboard_requisitionReports" onClick={(e) => gotoReports()}>
                                        <ArrowBack />&nbsp; Go Back to Reports
                                    </div>
                                </div>

                                <div className='contentcontainer'>
                                    <div className='sideContainer'>
                                        <Box className='search_main_Container'>
                                            <Box className='search_btn_box'>
                                                <div className='text_top'>
                                                    <Typography className=''>Showing Results for</Typography>
                                                    <Typography style={{ color: 'gray', marginTop: "2%" }}>Recruiter Activity Requisition Details</Typography>
                                                </div>
                                                <div className='search_btn_top'>
                                                    <button disabled={loading} className='search_btn' onClick={(event) => handleSearch(event)}>Search</button>
                                                </div>
                                            </Box>

                                            <Box sx={{ marginTop: "14px", padding: "0px 4px" }}>
                                                <div style={{ marginBottom: "10px" }}>
                                                    <Typography variant="subtitle1" gutterBottom>
                                                        Division
                                                    </Typography>
                                                    <Autocomplete
                                                        multiple
                                                        options={clientsDivisions.divisions}
                                                        getOptionLabel={(option) => option.divisionName}
                                                        onChange={handleDivisionChange}
                                                        renderInput={(params) => (
                                                            <TextField
                                                                {...params}
                                                                variant="outlined"
                                                                placeholder="Search Division"
                                                            />
                                                        )}
                                                    />
                                                </div>
                                                <div style={{ marginBottom: "10px" }}>
                                                    <Typography variant="subtitle1" gutterBottom>
                                                        Client Name
                                                    </Typography>
                                                    <Autocomplete
                                                        multiple
                                                        options={clientsDivisions.clients}
                                                        getOptionLabel={(option) => option?.clientName}
                                                        onChange={handleClientNameChange}
                                                        renderInput={(params) => (
                                                            <TextField
                                                                {...params}
                                                                variant="outlined"
                                                                placeholder="Search Client"
                                                            />
                                                        )}
                                                    />
                                                </div>
                                            </Box>
                                        </Box>
                                    </div>
                                    <div className='report_details_container'>
                                        <div className='tabsMainContainer_requisitionReports'>
                                            <div className='tabsContainer_requisitionReports'>
                                                <div className='tabs_requisitionReports_selected ' >Report Details</div>
                                            </div>
                                        </div>

                                        <div className='kendoContainer_requisitionReports'>
                                            <div className='export-button'>
                                                <Typography>{loading ? "Searching please wait..." : data[0]?.recruiterDetails?.displayName}</Typography>
                                                <Button className='excelButton_requisitionReports_dialog' disabled={loading} onClick={(e) => exportExcel()}>
                                                    <div className='excelButtonContainer_reports'>
                                                        <img src={ExcelImg} alt="excelImage" className='excelImg_requisitionReports' />
                                                        <span className='exportText_Dialog_reports'>Export</span>
                                                    </div>
                                                </Button>
                                            </div>
                                            <Grid filterable={false} ortable={true} resizable={true} reorderable={true}

                                                data={data}
                                                skip={page.skip || 0}
                                                take={page.take || 0}
                                                total={totalCount || 0}
                                                className="kendo_reports"
                                                pageable={{
                                                    pageSizes: [10, 20, 50, 100],
                                                    info: true,
                                                    previousNext: true,
                                                    buttonCount: 10,
                                                }}
                                                onPageChange={handlePageChange}
                                                onDataStateChange={changePageSize}
                                            >
                                                <GridNoRecords>
                                                    {loading ? jobReportSkeleton() : "No results found !"}
                                                </GridNoRecords>
                                                {
                                                    data && typeof data[0] === "object" &&
                                                    Object.keys(data[0])
                                                        .filter((item) => !filterFeilds.includes(item))
                                                        .map((elem) => {
                                                            let formattedTitle = elem
                                                                .replace(/([a-z])([A-Z])/g, "$1 $2")
                                                                .replace(/^./, (str) => str.toUpperCase());

                                                            if (formattedTitle === "Cand Names") {
                                                                formattedTitle = "Candidate Name";
                                                            }

                                                            return (
                                                                <GridColumn
                                                                    field={elem}
                                                                    title={formattedTitle}
                                                                    width="180px"
                                                                    filterable={false}
                                                                    cell={elem === "candNames" ? expandableColumns : undefined}
                                                                />
                                                            );
                                                        })
                                                }


                                            </Grid>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='bottomContainer_requisitionReports'>
                            <Footer />
                        </div>

                    </div>

                    {/* <NotificationContainer /> */}

                </>
                :
                <>
                    <div className='Unauthorized_container'>
                        <BasicHeader />
                        <div className="row padding_80 text-center">
                            <div className='UnAuthorized_contain'>
                                <p className="fs-semi-bold fs-40 f-color authorize">
                                    You Are Not Authorized To Visit This Page
                                </p>
                            </div>
                        </div>
                    </div>
                </>
            }
        </>
    )
}

export default RequisitionReportDetails