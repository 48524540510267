import React from 'react';
import { useState, useMemo } from "react";
import { codeTofield } from "../GlobalAssistingFunctions/GlobalAssistingFunctions";
import "./activityTabs.css"
import DeleteOutlineIcon from '@material-ui/icons/DeleteOutline';
import EditSharpIcon from '@material-ui/icons/EditSharp';
import DeleteActivity from "./DeleteActivity.js";
import deleteCandidateActivityEventById from '../../apis/deleteCandidateActivityEventById/deleteCandidateActivityEventById.js';
import {
    NotificationContainer, NotificationManager
} from "react-notifications";

const AcitvityTabs = ({Id, setUpdate, data, setIndex, selectedCandReq, closeEdit, moveCandidate, userType, userIdType ,reqId,email}) => {
    const [loader,setLoader]=useState(false)
    const [deleteIndex, setDeleteIndex] = useState(null); // State to track the item being deleted
    // switch modal and record the index of the clicked event
    const handleTabOnclick = (i) => {
        setIndex(i);
        setUpdate(false)
    }

    // let tabs = useMemo(() => data, [data]);

    // Handle the delete click event to open the DeleteActivity dialog
    const handleDeleteComponent = (i) => {
        setDeleteIndex(i); // Set the index of the item to be deleted
    }

    // Handle the actual delete confirmation logic
    const handleConfirmDelete = async () => {
        setLoader(true)

        let resp = await deleteCandidateActivityEventById({ requisitionId:reqId, email,dataDelete : data?.at(-1), update : data?.at(-2),Id, senderId: userIdType });

        if (resp?.status === 200) {
            NotificationManager.success("Candidate status updated successfully", "Success", 2000);
            let payload = resp?.data?.candidateRequisition
            moveCandidate(payload, payload?.email, data?.at(-1)?.candidateStatusCode, payload?.status?.candidateStatusCode, !payload.isActive)
        }
        closeEdit()
        setLoader(false)
    }


    return (
        <>
            {
                // if no data 
                data?.length > 0 ?
                    <div className='recentActivities_Container activityActionContainer' >
                        {data?.map((elem, i) => {
                            return <div key={"ActivityTabs " + i} className=' activityCapsule' >
                                <div className="activityTabsTitleAction">{codeTofield(elem.candidateStatusCode)}</div>
                                <div className="activityActionsTabs">
                                    <span onClick={() => { handleTabOnclick(i) }}>
                                        Edit<EditSharpIcon className="editIconeActivityTabs" />
                                    </span>
                                    {
                                        // show delete only for the last event
                                        i === data?.length - 1 && userType === "MIS Operator" && <span onClick={() => handleDeleteComponent(i)} >Delete<DeleteOutlineIcon className="deleteIconeActivityTabs"
                                        /></span>
                                    }

                                </div>
                            </div>
                        })}
                    </div>
                    :
                    <div className='loaderForEdit'><h1>No events found</h1></div>

            }


            {/* Conditionally render the DeleteActivity dialog when deleteIndex is not null */}
            {deleteIndex !== null && (
                <DeleteActivity
                    onConfirm={handleConfirmDelete} // Handle delete confirmation
                    onCancel={() => {setDeleteIndex(null);setLoader(false)}} // Handle cancel action
                    disabled={loader}
                />
            )}
            <NotificationContainer />
        </>
    )
}

export default AcitvityTabs
