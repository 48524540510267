// teams/{orgId}/leaders/{recruiterId}
import axios from "axios";
const jsonConfig = require("../../Config.json");

const getLeaders = async (orgId,recruiterId) => {
  try {
    const resp = await axios.get(
      jsonConfig.apiUrl +
        "teams/" +orgId+"/leaders/"+recruiterId,
      {
        headers: {
          "x-functions-key": jsonConfig.key,
        },
      }
    );

    return resp
  } catch (err) {
    console.log(err);
    return Promise.reject(err);
  }
};

export default getLeaders;
