import getCandidateRequisitionByRequisitionIdAndCandidateEmail from "../../apis/getCandidateRequisitionByRequisitionIdAndCandidateEmail/getCandidateRequisitionByRequisitionIdAndCandidateEmail";
import {
  codeTofield,
  filterFunc,
} from "../GlobalAssistingFunctions/GlobalAssistingFunctions";
import getDynamicFieldsByStatus from "../../apis/getAllDynamicStatusFields/getDynamicFieldsByStatus";
import { DateTime } from "luxon";
import getRecruiterRequisitionById from "../../apis/getRecruiterRequisitionById/getRecruiterRequisitionById";
import { NotificationManager } from "react-notifications";


export const createEventStatus = (payloadData, userId, fields, id, setLoader) => {
  let activityLogs = updateEventStatus(payloadData, id, fields, setLoader);

  return {
    candidateStatusCode: payloadData?.status?.candidateStatusCode,
    candidateStatusCodeId: payloadData?.status?.candidateStatusCodeId,
    created_at: DateTime.now().toISO(),
    createdBy: userId,
    activityLogs: [activityLogs],
    isDeleted: false,
    isCurrentlyUsed: true,
    isDeletedlog: [],
    isCurrentlyUsedlog: [payloadData.displayDate],
  };
};

// export const updateEventStatus = (eventStatus, payloadData, userId) => {
//     eventStatus.updated_at = DateTime.now().toISO();
//     eventStatus.isDeleted = false;
//     eventStatus.isCurrentlyUsed = true;
//     eventStatus.isDeletedlog = [];

//     eventStatus.activityLogs.push({
//         displayDate: DateTime.now().toISO(),
//         eventDate: payloadData.eventDate || null,
//         notes: payloadData.notes,
//         salary: payloadData.salary,
//         hourlyMargin: payloadData.hourlyMargin,
//         endDate: payloadData.endDate,
//         hourlyPayRate: payloadData.hourlyPayRate,
//         contractualType: payloadData?.contractualType || undefined,
//         submissionStatus: payloadData?.submissionType || null,
//         updatedBy: userId,
//     });
// };

export const updateEventStatus = (payloadData, userId, fields, setLoader) => {
  let activityLogs = {};

  for (let field of fields?.fields) {
    let val =
      field?.dataType !== "Number"
        ? payloadData[field?.altName]
        : Number(payloadData[field?.altName]);
    activityLogs[field?.altName] = val;

    if (!activityLogs[field?.altName]){
      setLoader(elem=>({...elem,next:false}))
      throw new Error(`${field?.name} is mandatory`);
    }
  }

  if (fields?.submissionType?.length > 0) {
    if (!payloadData["submissionType"])
    {
      setLoader(elem=>({...elem,next:false}))
      throw new Error(`Submission Type is mandatory`);
    }
    activityLogs["submissionStatus"] = payloadData["submissionType"];
  }

  if (fields?.contractualType?.length > 0) {
    if (!payloadData["contractualType"]){
      setLoader(elem=>({...elem,next:false}))
      throw new Error(`Contractual Type is mandatory`);
    }
    activityLogs["contractualType"] = payloadData["contractualType"];
  }

  activityLogs["updatedBy"] = userId;

  return activityLogs;
};

// get list of status that comes after current  status of candidateRequisition
export const getFilteredRequisitionStatus = (statusOptions, orignalData) => {
  if (!statusOptions || statusOptions?.length <= 0 || !orignalData?.statusName)
    return [];

  return statusOptions
    .filter((elem) => elem.name === orignalData.statusName)[0]
    ?.statusOptions.map((elem) => {
      return codeTofield(elem);
    });
};

export function convertDate(str) {
  if (str === "") {
    return "";
  }

  const date = new Date(str);

  return DateTime.fromJSDate(date).toISODate();
}

export const fetchDydamicFields = async (
  setLoader,
  statusOptions,
  submitDataFromModal,
  setSubmitDataFromModal,
  setRequiredfields,
  orignalData
) => {
  setLoader((elem) => ({ ...elem, loading: true }));

  let statusCode = filterFunc(
    statusOptions,
    (elem, match) => elem.name === match?.statusName,
    submitDataFromModal
  )[0]?.candidateStatusCode;
  let placement_type = submitDataFromModal?.requisitionDetails?.placementType;

  if (statusCode && placement_type) {
    const res = await getDynamicFieldsByStatus(statusCode, placement_type);
    setRequiredfields(res?.data);
    //  if selected status is new status of candidate requistion then show null fields
    if (
      orignalData?.statusName &&
      submitDataFromModal?.statusName &&
      orignalData.statusName !== submitDataFromModal.statusName &&
      orignalData?.requisitionDetails?.reqreferenceId ===
      submitDataFromModal?.requisitionDetails?.reqreferenceId
    ) {
      setSubmitDataFromModal((elem) => {
        let temp = { ...elem };

        let field = [
          "eventDate",
          "notes",
          "hourlyMargin",
          "endDate",
          "hourlyPayRate",
        ];
        field.forEach((f) => {
          temp[f] = "";
        });
        temp["displayDate"] = DateTime.now().toISODate();
        return temp;
      });
    }
  }
  setLoader((elem) => ({ ...elem, loading: false }));
};

// get candidate existing req
export const getCanReq = async (payload, id, setCandidateReqId) => {
  let resp = (
    await getCandidateRequisitionByRequisitionIdAndCandidateEmail(payload)
  )?.data?.data;
  let data = { canReqExist: false };

  setCandidateReqId(resp?._id);
  
  console.log(resp._id)
  
  let recid = id;
  // recruiterId
  let currentStatus = filterFunc(
    resp?.eventStatus || [],
    (element) =>
      element?.isCurrentlyUsed &&
      resp?.status?.candidateStatusCode === element?.candidateStatusCode
  )?.at(-1);

  let teamMatch = true;
  if (currentStatus && resp?.isActive) {
    const response = await getRecruiterRequisitionById(
      resp?.requisitionRecruiterId
    );

    let recriterReq = response.data.requisitionData;
    let recruiterRequisitionTeamId = recriterReq.team.teamId;
    let recruiterCurrentTeam = response.data.recruiterCurrentTeam;
    teamMatch = recruiterRequisitionTeamId === recruiterCurrentTeam;
    data = resp;
    let statusName = codeTofield(currentStatus?.candidateStatusCode);
    data = { ...data, canReqExist: true, statusName };
    recid = recriterReq?.recruiterId;
  }

  return { data, recidInCanReq: recid, teamMatch };
};


export const verifyForm = (formData,requiredFields) => {

  for (let field of requiredFields) {
      if (!formData[field]) {
          NotificationManager.error(field + " missing ", "Error", 4000);
          return false;
      }
  }

  return  {
    recruiterLeaderId: formData.RL.id,
    teamLeaderId: formData.TL.id,
    gender: formData?.gender?.name || formData?.gender,
    currentStatus: formData.currentStatus?.name || formData.currentStatus,
  }
 
};