import axios from "axios";
import * as fflate from 'fflate';
const jsonConfig = require("../../Config.json");

const activityReport = async (payload) => {
    try {
        const resp = await axios.post(
            jsonConfig.apiUrl + "ActivityReports",
            payload,
            {
                headers: {
                    "x-functions-key": jsonConfig.key,
                },
            });
        let decompressedObject;
        if (resp?.data && resp?.data?.compressedbase64string) {
            const strData = atob(resp.data.compressedbase64string);
            const charData = strData.split("").map((x) => {
                return x.charCodeAt(0);
            });
            const binData = new Uint8Array(charData);
            let apiDataUnCompressed = fflate.unzlibSync(binData);
            let apiDataUnCompressedString = fflate.strFromU8(apiDataUnCompressed);
            decompressedObject = JSON.parse(apiDataUnCompressedString);
            console.log({ decompressedObject })
        }
        return Promise.resolve(decompressedObject);
    }
    catch (err) {
        return Promise.reject(err);
    }
}

export default activityReport;