import axios from "axios";
const jsonConfig = require("../../Config.json");


const getRecruiterRequisitionById = async (recruiterRequisitionId) => {
    try {
        const response = await axios.get(jsonConfig.apiUrl + `getRecruiterRequisitionById/${recruiterRequisitionId}`,{
            headers: {
              "x-functions-key": jsonConfig.key,
            },
        });
        return Promise.resolve(response);
    }
    catch(err) {
        return Promise.reject(err);
    }
}

export default getRecruiterRequisitionById;