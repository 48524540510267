import { useState, useCallback, useEffect } from "react";
import {
    Grid as GridDiv,
    GridColumn,
    GridNoRecords,
} from "@progress/kendo-react-grid";
import debounce from "lodash/debounce";
import { Skeleton } from "@material-ui/lab";
import { makeStyles } from "@material-ui/core/styles";
import { DateTime } from "luxon";
import * as XLSX from "xlsx";
import { NotificationManager } from "react-notifications";
import { Backdrop, CircularProgress, Typography } from "@material-ui/core";
import { convertDate } from "../AcitivityModal/assistingFunctions";
import activityReport from "../../apis/activityReport/activityReport";
// import { convertDate } from "./AcitivityModal/assistingFunctions";
const useStyles = makeStyles((theme) => ({
    odd: {
        background: "#F6F6F6 !important",
    },
    even: {
        background: "#fffff !important",
    },
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: "#fff",
    },
    waitingText: {
        marginLeft: "5px",
    },
}));
const ActivityReport = (props) => {
    const {
        userType,
        userIdType,
        userRecruitmentpartnerId,
        startSelectedDate,
        endSelectedDate,
        exports,
        additionalFilter,
        setTriggerActivity
    } = props;


    const [page, setPage] = useState({ skip: 0, take: 10 });
    const [isExporting, setIsExporting] = useState(false);
    const [totalResult, setTotalResult] = useState(0);

    const classes = useStyles();

    const [dataResult, setDataResult] = useState({});
    const [loading, setLoading] = useState(true)
    const columns = [
        { title: "Name", "field": "displayName", "width": "180px" },
        { title: "Email", "field": "email", "width": "180px" },
        { title: "Candidate Submitted", "field": "documentCount", "width": "180px" },
        { title: "Duration", "field": "duration", "width": "180px" },

    ]

    let hasAdditionalFilterData = Object.values(additionalFilter).some(
        (value) => Array.isArray(value) ? value.length > 0 : !!value
    );



    const debouncedSearch = useCallback(
        debounce(
            async (
                page,
                additionalFilter,
                startSelectedDate,
                endSelectedDate
            ) => {

                try {
                    setDataResult({});
                    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
                    let payload = {
                        "userRecruitmentpartnerId": userRecruitmentpartnerId,
                        "page": page.skip / page.take + 1,
                        "size": page.take,
                        "formattedDatestart": startSelectedDate,
                        "formattedDateend": endSelectedDate,
                        "isExport": false,
                        "userId": userIdType,
                        "role": userType,
                        timezone,
                        "additionalFilter": additionalFilter
                    }

                    const search = await activityReport(payload);

                    if (search?.totalCount[0].total[0].total >= 0) {

                        let total = search?.totalCount[0].total[0].total
                        let data = search?.data

                        setDataResult({ data, total });

                        setTotalResult(total);
                    }
                    else {
                        setDataResult({ data: null })
                        setTotalResult(0);
                    }
                }
                catch (error) {
                    setDataResult({ data: null })
                    setLoading(false)

                }
                setLoading(false)
            },
            1000
        ),
        []
    );


    useEffect(() => {
        const startDate = convertDate(startSelectedDate);
        const endDate = convertDate(endSelectedDate);

        if (!startSelectedDate || !endSelectedDate || startDate > endDate) {
            setDataResult({})
            NotificationManager.error("Please enter a valid date", "Error", 2500);
        } else {
            setLoading(true)
            // Reset page number to 1 if hasAdditionalFilterData is true
            if (hasAdditionalFilterData) {
                setPage(1);
            }
            debouncedSearch(page, additionalFilter, startDate, endDate);
        }
    }, [page, startSelectedDate, endSelectedDate, additionalFilter]);

    useEffect(() => {
        if (exports)
            callApiToGenerateExcel()
    }, [exports])

    const callApiToGenerateExcel = async () => {
        const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        let maxSize = 100;
        let end = Math.ceil(totalResult / maxSize);
        let exportDataArr = [];
       

        for (let start = 0; start < end; start++) {
            let payload = {
                "userRecruitmentpartnerId": userRecruitmentpartnerId,
                "page": 1,
                "size": maxSize,
                "formattedDatestart": convertDate(startSelectedDate),
                "formattedDateend": convertDate(endSelectedDate),
                "isExport": true,
                "userId": userIdType,
                "role": userType,
                "additionalFilter": additionalFilter,
                timezone
            }
           
            let search = await activityReport(payload);
            
            search?.data.forEach(({ displayName, documentCount: candidate_submit, duration, ...rest }) => {
                exportDataArr.push({ displayName, candidate_submit, duration, ...rest });
            });

        }
        setIsExporting(true);
        // let exportData = search?.data

        if (end === 0 && exportDataArr.length === 0) {
            NotificationManager.error("No data available", "Error", 2500);
            setTriggerActivity(false)
            setIsExporting(false);
            return;
        }

        const ws = XLSX.utils.json_to_sheet(exportDataArr);

        const wb = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(wb, ws, "Data");
        XLSX.writeFile(wb, "Access log report.xlsx");
        setTriggerActivity(false)
        setIsExporting(false);
    };

    const jobReportSkeleton = () => {
        let arr = [];
        for (let i = 0; i <= 10; i++) {
            arr.push(
                <Skeleton
                    key={"HiringReportSkeleton" + i}
                    className={i % 2 === 0 ? classes.odd : classes.even}
                    variant="rect"
                    width="100%"
                    height="50px"
                />
            );
        }
        return arr;
    };

    const changePageSize = (e) => {
        if (loading)
            return
        setPage((elem) => {
            return {
                ...elem,
                take: e.dataState.take
            }
        });
    };

    const handlePageChange = (e) => {
        if (loading)
            return
        setPage({
            skip: e.page.skip,
            take: e.page.take
        });
    };


    return (
        <>
            <Backdrop className={classes.backdrop} open={isExporting}>
                <CircularProgress color="inherit" />
                <Typography className={classes.waitingText}>
                    {" "}
                    Please wait ...
                </Typography>
            </Backdrop>
            <GridDiv
                filterable={false}
                ortable={true}
                resizable={true}
                reorderable={true}
                pageable={{
                    pageSizes: [10, 20, 50, 100],
                    info: true,
                    previousNext: true,
                    buttonCount: 10,
                }}
                skip={page.skip}
                take={page.take}
                data={dataResult?.data}
                total={dataResult?.total || 0}
                onPageChange={handlePageChange}
                onDataStateChange={changePageSize}
                className="kendo_reports"
            >
                <GridNoRecords>
                    {loading ? jobReportSkeleton() : "No results found !"}
                </GridNoRecords>

                {columns && columns.map((column) => {
                    return (
                        <GridColumn
                            key={column.field}
                            field={column.field}
                            // title={column.title}
                            width={column.width}
                            filterable={false}
                            title={column.title}
                            {...(column.cell && { cell: column.cell })}
                            {...(column.format && { format: column.format })}
                        />
                    )
                }

                )}
            </GridDiv>
        </>
    );
};


export default ActivityReport;