

import axios from "axios";
import * as fflate from 'fflate';
const jsonConfig = require("../../Config.json");

const getCandidateFieldOptions = async (id) => {
  try {
    const resp = await axios.get(
      jsonConfig.apiUrl + 'getCandidateFieldOptions',
      {
        headers: {
          "x-functions-key": jsonConfig.key,
        },
      }
    );
    let decompressedObject;
    if (resp?.data && resp?.data?.compressedBase64String) {
        const strData = atob(resp.data.compressedBase64String);
        
      const charData = strData.split("").map((x) => {
        return x.charCodeAt(0);
      });
      const binData = new Uint8Array(charData);
      let apiDataUnCompressed = fflate.unzlibSync(binData);
      let apiDataUnCompressedString = fflate.strFromU8(apiDataUnCompressed);
      decompressedObject = JSON.parse(apiDataUnCompressedString);
    }

    return decompressedObject
  } catch (err) {
    console.log(err);
    return Promise.reject(err);
  }
};

export default getCandidateFieldOptions;
