import axios from "axios";
const jsonConfig = require("../Config.json");



const userSessionLogs = async (userId, token) => {
  try {
    const getAllResponse = await axios.post(
      jsonConfig.apiUrl + "userSessionLogs",
      { "userId": userId, token },
      {
        headers: {
          "x-functions-key": jsonConfig.key,
        },
      }
    );
    return Promise.resolve(getAllResponse);
  } catch (error) {
    return Promise.reject(error);
  }
};
export default userSessionLogs;
