import React, { useContext, useEffect, useState } from 'react'
import './AtsDashNav.css';
import { List } from '@material-ui/core';
import MenuOpenIcon from '@material-ui/icons/MenuOpen';
import { CloudUpload, Search } from '@material-ui/icons';
import MarkunreadMailboxIcon from '@material-ui/icons/MarkunreadMailbox';
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';
import AssignmentTurnedInIcon from '@material-ui/icons/AssignmentTurnedIn';
import DescriptionIcon from '@material-ui/icons/Description';
import PeopleIcon from '@material-ui/icons/People';
import RecentActorsIcon from '@material-ui/icons/RecentActors';
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import { useHistory } from 'react-router-dom';
import BarChartIcon from '@material-ui/icons/BarChart';
import { makeStyles } from "@material-ui/core/styles";
import "../../pages/myTalentCommunity/myTalentCommunity.css";
import RecordVoiceOverIcon from '@material-ui/icons/RecordVoiceOver';
import ContactMailIcon from '@material-ui/icons/ContactMail';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import Collapse from '@material-ui/core/Collapse';
import InboxIcon from '@material-ui/icons/MoveToInbox';
import ListSubheader from '@material-ui/core/ListSubheader';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import StarBorder from '@material-ui/icons/StarBorder';
import AmpStoriesIcon from '@material-ui/icons/AmpStories';
import PersonIcon from '@material-ui/icons/Person';
import AssignmentIndIcon from '@material-ui/icons/AssignmentInd';
import allRoles from '../../apis/getAllRoles/getRoles';
import { UserTypeContext } from '../../AppRoute';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
        maxWidth: 360,
        //   backgroundColor: theme.palette.background.paper,
    },
    // - No need to do anything on hover

    nested: {
        paddingLeft: theme.spacing(2),
        paddingTop: 0,
        paddingBottom: 0,
        fontSize: '14px',
    },

}));


function AtsDashNav(props) {
    const { idContextTest, orgReferral, recruitementId, recruiterAgencyType, userRole, userType, userRecords } = props
    const { setShowResumeModal } = props; // Add this line to destructure setShowResumeModal from props
    const history = useHistory();
    const classes = useStyles();
    const [administrationMenuOpen, setadministrationMenuOpen] = useState(false);
    const [roleCodes, setRoleCodes] = useState([])
    const [roleDropdown, setRoleDropdown] = useState(false)
    const { switchRole } = useContext(UserTypeContext)

    useEffect(() => {

        const roleFetcher = async () => {
            try {

                const roles = await allRoles()

                if (roles.status === 200) {
                    const Role = roles?.data?.data?.filter((elem) => elem?.id === userRecords?.roleId)[0]
                    const codes = Role?.accessRoles
                    setRoleCodes(codes || [])
                    console.log(codes,Role)
                }
            }
            catch (error) {
                console.log(error)
                setRoleCodes([])
            }
        }

        roleFetcher()
    }, [])

    const administrationOpen = () => {
        setadministrationMenuOpen(!administrationMenuOpen);
    };

    const navigateTo = (pageName) => {
        history.push({
            pathname: `/${pageName}`,
            state: { id: idContextTest, recruitmentPartnerId: recruitementId, userType: userType, userRecords: userRecords, userRole: userRole },
        });
    }

    const goToRequisitionReports = () => {
        history.push("/requisition-reports", { id: idContextTest, userType: userType, userRecords: userRecords, userRole: userRole, userRecruitmentpartnerId: recruitementId });
    }

    const handleUploadResumes = () => {
        setShowResumeModal(true);
    }

    const gotoPendingInvites = () => {

        history.push({
            pathname: "/pending-invites",
            state: { id: idContextTest, userType: userType, userRecords: userRecords, userRole: userRole, userRecruitmentpartnerId: recruitementId },
        });
    }
    const roleSwitcher = (role) => {
        switchRole(role)
        setRoleDropdown(elem => !elem)
    }

    return (
        <div className='ats-dash-nav-container'>
            <div className='ats-dash-navTitle' >{userType === 'Admin' ? userRole : userType}'s Dashboard</div>
            <List>
                <div className='ats-dash-navItem ats-dash-navItem-selected' onClick={() => navigateTo('Dashboard')}><MenuOpenIcon /> Feed</div>
                <List
                    component="nav"
                    aria-labelledby="nested-list-subheader"

                    className={classes.root}
                >
                    {
                    userType !=="Recruiter" && userRole!=="Recruiter" && <div className='ats-dash-navItem ' onClick={() => setRoleDropdown((elem) => !elem)}><AmpStoriesIcon /> Switch Role {roleDropdown ? <ExpandLess /> : <ExpandMore />}
                    </div>
                    }
                    <Collapse in={roleDropdown} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                            {roleCodes.filter((elem)=>elem!==userType).map((elem) => {
                                return (

                                    <ListItem key={elem} button className={classes.nested}>
                                        <div className='ats-dash-navItem ' onClick={() => roleSwitcher(elem)}><PeopleIcon />{elem}</div>
                                    </ListItem>
                                )
                            })
                            }

                        </List>
                    </Collapse>
                </List>
                <div className='ats-dash-navItem ' onClick={() => navigateTo('candidate-search-talent-community')}><Search /> Candidate Search</div>
                {userType === 'Admin' || userType === 'Organization Admin' ? `` :
                    <div className='ats-dash-navItem ' onClick={(e) => handleUploadResumes()}><CloudUpload /> Upload Resume</div>
                }
                {userType === 'Admin' || userType === 'Organization Admin' ? `` :
                    <div className='ats-dash-navItem ' onClick={() => navigateTo('invite-candidates')}><MarkunreadMailboxIcon /> Invite Candidate To Take Test</div>
                }
                <div className='ats-dash-navItem' onClick={() => gotoPendingInvites()}><HourglassEmptyIcon /> Pending Invites</div>
                {
                    (userType === 'Admin') ? "" :
                        (orgReferral) ?
                            ((recruiterAgencyType !== "internal") ?
                                <></>
                                :
                                <div className='ats-dash-navItem ' onClick={() => navigateTo('mytalent-referral')} ><RecordVoiceOverIcon /> Pending Referral</div>

                            ) :
                            <></>
                }
                <div className='ats-dash-navItem ' onClick={() => navigateTo('pending-resume-validations')}><AssignmentTurnedInIcon /> Pending Resume Validation</div>

                <div className='ats-dash-navItem ' onClick={() => navigateTo('requisition-dashboard')}><DescriptionIcon /> Requisitions</div>

                {userType === "Organization Admin" || userType === "MIS Operator" || (userType === 'Admin' && userRole === 'Organization Admin') || (userType === 'Admin' && userRole === 'MIS Operator') ?
                    <>
                        <List
                            component="nav"
                            aria-labelledby="nested-list-subheader"

                            className={classes.root}
                        >
                            <div className='ats-dash-navItem ' onClick={() => administrationOpen()}><AmpStoriesIcon /> Administration {administrationMenuOpen ? <ExpandLess /> : <ExpandMore />}
                            </div>

                            <Collapse in={administrationMenuOpen} timeout="auto" unmountOnExit>
                                <List component="div" disablePadding>
                                    <ListItem button className={classes.nested}>
                                        <div className='ats-dash-navItem ' onClick={() => navigateTo('orgManageTeam')}><PeopleIcon /> Manage Team</div>
                                    </ListItem>
                                    <ListItem button className={classes.nested}>
                                        <div className='ats-dash-navItem ' onClick={() => navigateTo('orgManageDivision')}><AccountTreeIcon /> Manage Division</div>
                                    </ListItem>
                                    <ListItem button className={classes.nested}>
                                        <div className='ats-dash-navItem ' onClick={() => navigateTo('orgManageClient')}><RecentActorsIcon /> Manage Client</div>
                                    </ListItem>
                                    <ListItem button className={classes.nested}>
                                        <div className='ats-dash-navItem ' onClick={() => navigateTo('orgManageSpoc')}><PersonIcon /> Manage POC</div>
                                    </ListItem>
                                    <ListItem>
                                        {userType === "Organization Admin" || userType === "MIS Operator" || (userType === 'Admin' && userRole === 'Organization Admin') || (userType === 'Admin' && userRole === 'MIS Operator') ?
                                            <div className='ats-dash-navItem ' onClick={() => navigateTo('users')}><AssignmentIndIcon /> Manage User</div>
                                            : ""}
                                    </ListItem>
                                </List>
                            </Collapse>
                        </List>
                        {
                            (userType === 'Admin') ?
                                '' :
                                <div className='ats-dash-navItem ' onClick={() => navigateTo('sign-up-page')}><ContactMailIcon /> Sign Up Requests</div>
                        }
                    </>
                    : ""
                }





                {userType === "Organization Admin" || userType === 'Recruitment Leader' || userType === "Team Leader" || (userType === 'Admin' && (userRole === 'Organization Admin' || userRole === 'Recruitment Leader')) ?
                    <div className='ats-dash-navItem ' onClick={() => goToRequisitionReports()} ><BarChartIcon /> Reports</div>

                    : ""
                }


            </List>


        </div>
    )
}

export default AtsDashNav