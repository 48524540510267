import { Skeleton } from "@material-ui/lab";
import config from "../../../Config.json"

export const jobReportSkeleton = () => {
    let arr = [];
    for (let i = 0; i <= 10; i++) {
        arr.push(
            <Skeleton
                key={i + "req_rep_details"}
                className={i % 2 === 0 ? "req_details_evenRow" : "req_details_oddRow"}
                variant="rect"
                width="100%"
                height="50px"
            />
        );
    }
    return arr;
};


export const fixData = (Data,field) => {
    if(!Data )
        return 

    let tempData = []
    Data.assignedRequisitions.forEach((elem) => {

        const FindDateName = (requisition) => {

            return requisition.map((innerELem) => {
                                
                let startedReq=innerELem?.eventStatus?.filter(innerMostElem=>innerMostElem?.candidateStatusCode===config.candidateRequisitionStatusCode.STARTED && 
                    innerMostElem?.isCurrentlyUsed
                )?.at(0) || []
                
                let date=startedReq?.activityLogs?.at(-1)?.eventDate || null

                return {
                    name: innerELem.name,
                    date: date ? date.slice(0, date.indexOf('T')) : ""
                }
            })
        }

        let coveredCandidateRequisitions = Data.candidateRequisitionsData.filter((innerELem) => {
            return innerELem["requisitionRecruiterId"] === elem["_id"]
        })
        
        let date_name = FindDateName(coveredCandidateRequisitions)
        

        // if (field === "" || (field === elem.status.subCode) || date_name.length > 0)
            tempData.push({
                // "Recruiter Name": Data.recruiterDetails.firstName + " " + Data.recruiterDetails.lastName,
                "Client Name": elem.clientName,
                "Division Name": elem.division.divisionName,
                "Position Title": elem.placementType,
                "Candidate Requisition Details": date_name
                // "Job Id": "",
            })

    })

    // console.log({tempData})
    return { tempData, recName: Data.recruiterDetails.firstName + " " + Data.recruiterDetails.lastName, length: Data.assignedRequisitionsCount };

}

export const expandableColumns = (event) => {
    
    // let rowData = event.dataItem[event.field]
    // if (rowData.length === 0)
        // return <td>{event?.dataItem[event?.field]?.displayName}</td>
    const rowData = event.dataItem[event.field]
    if (rowData.length === 1)
        return <td>
            <>
                <span>Consultant Name :  {rowData[0].consultantName}</span>
                {
                    rowData[0].startDate ? <p> Start Date : {rowData[0].startDate} </p> : ""
                }
            </>
        </td>
    return <td >
        <details onClick={(event) => event.currentTarget.open = false} className="requisiton_report_details">
            <summary className="name_date_column">
                <span  ><span>Consultant Name {": " + rowData[0].consultantName}</span>
                    {
                        rowData[0].startDate ? <p>Start Date : {rowData[0].startDate}</p> : ""
                    }
                </span>
            </summary>
            {
                rowData.slice(1).map((item, index) => (
                    <div className="name_date_column content" key={index + item}>
                        <span>Consultant Name {": " + item.consultantName}</span>
                        <p>{item.startDate ? "Start Date : " + item.startDate: ""}</p>
                    </div>)
                )
            }
            <div className="requisition_report_details_close">...hide more</div>
        </details>

    </td>
}