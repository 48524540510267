import { useState, useEffect, useContext } from 'react'
import './ReassignRecruiterModal.css';
import { useHistory } from 'react-router-dom';
import { CircularProgress, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField, Typography, makeStyles } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { GridColumn } from "@progress/kendo-react-grid";
import { Grid as KendoGrid } from "@progress/kendo-react-grid";
import { reassignRecruiter } from '../../apis/assignRecruiter/assignRecruiter';
import { NotificationContainer, NotificationManager } from "react-notifications";
import checkExistsRequisition from '../../apis/checkRequisitionExists/checkRequisitionExists';
import { UserTypeContext } from "../../AppRoute";

const useStyles = makeStyles((theme) => ({
    odd: {
        background: '#F6F6F6 !important'
    },
    even: {

        background: '#fffff !important'
    },
}))


function ReassignRecruiterModal(props) {
    const { userType, userRecords } = useContext(UserTypeContext);
    const idContextTest = userType === "Admin" ? props?.location?.state?.id : userRecords?.id
    const userRecruitmentpartnerId = userType === "Admin" ? props?.location?.state?.recruitmentPartnerId : userRecords?.recruitmentpartnerId
    const { openDialogRecruiterAssingment, setopenDialogRecruiterAssingment, teamInfo, setTeamInfo, recruiterOptions, setRecruiterOptions, setactiveRequAssign, activeRequAssign, selctedRecruiter, teamRecruiter, handleSubmit, setOpenDialog } = props
    const [openDialogconfirmation, setopenDialogconfirmation] = useState(false);
    const [openDialogRecruiterAssign, setDialogRecruiterAssign] = useState(false);
    const [defaultRecruiter, setDefaultRecruiter] = useState([]);
    const [reassignCheck, setreassignCheck] = useState(true);
    const [reassignCheckLoader, setreassignCheckLoader] = useState(false);
    const [reassignSaveCheck, setreassignSaveCheck] = useState(true);
    const [reassignDefaultCheck, setreassignDefaultCheck] = useState(false);
    const [isUserDataUpdating, setIsUserDataUpdating] = useState(false)

    const history = useHistory();
    const classes = useStyles()
    const [dataStateRec, setDataStateRec] = useState({
        skip: 0,
        take: 200,
        sort: [
            {
                field: "",
                dir: "desc",
            },
        ],
    });

    const deleteRecruiterConfirm = async () => {
        setIsUserDataUpdating(true)
        await handleSubmit();
        setDialogRecruiterAssign(false)
        setopenDialogconfirmation(false)
        setIsUserDataUpdating(false)
        setreassignDefaultCheck(false)
        setDefaultRecruiter([])
    }


    const handelRecruiterEmailChange = (val, prop) => {
        let activeRequsition = [...activeRequAssign]
        for (let i = 0; i < activeRequsition.length; i++) {

            if (activeRequsition[i].requisitionDetailId == prop.requisitionDetailId) {
                activeRequsition[i].reasssingRecruiter = val
                break;
            }
        }
        setactiveRequAssign(activeRequsition)
    }


    const handleDialogRecruiterAssingment = () => {
        setopenDialogRecruiterAssingment(false);
        setDialogRecruiterAssign(true)
        setreassignSaveCheck(true)
        setreassignDefaultCheck(false)
        setDefaultRecruiter([])
    };
    const closeModalRecruiterAssign = () => {
        setDefaultRecruiter([])
        setDialogRecruiterAssign(false)
        setreassignDefaultCheck(false)
        setOpenDialog(true)
    };

    const handleCloseDialogRecruiterAssingment = () => {
        setopenDialogRecruiterAssingment(false);
        setOpenDialog(true)
    };

    const handleDefaultChange = (e, value) => {
        for (let i = 0; i < activeRequAssign.length; i++) {
            activeRequAssign[i].reasssingRecruiter = value
        }
        setDefaultRecruiter(value)
        setreassignCheck(false)
    }
    const dataStateChangeRec = (event) => {
        setDataStateRec(event.dataState);
    };
    const handelCloseConfirmation = () => {
        setopenDialogconfirmation(false)
    }
    const handelChangeReassign = async () => {
        setreassignCheckLoader(true)

        const reassignList1 = []
        try{

        for (let i = 0; i < activeRequAssign.length; i++) {
            let payload = {
                requisitionDetailId: activeRequAssign[i].requisitionDetailId,
                requisitionId: activeRequAssign[i].requisitionId,
                oldRceruiterId: activeRequAssign[i].recruiterId,
                newRecruiterId: activeRequAssign[i].reasssingRecruiter.id,
                "notificationList": [
                    {
                        "id": activeRequAssign[i].recruiterId,
                        "email": selctedRecruiter.email,
                        "displayName": selctedRecruiter.displayName,
                        "status": -1
                    },
                    {
                        "id": activeRequAssign[i].reasssingRecruiter.id,
                        "email": activeRequAssign[i].reasssingRecruiter.email,
                        "displayName": activeRequAssign[i].reasssingRecruiter.displayName,
                        "status": 0
                    }
                ],
                senderId: idContextTest,
                reqreferenceId: activeRequAssign[i].requisitionDetail.reqreferenceId,
                displayName: userRecords?.displayName,
                recruitmentpartnerId: activeRequAssign[i].recruitmentpartnerId,
                "istlnf": false,
                teamId:teamInfo.teamId
            }
            reassignList1.push(payload)
        }

        const reassignList = [];
        var response = ''
        for (let i = 0; i < reassignList1.length; i += 5) {
            let arr = (reassignList1.slice(i, i + 5));
            const payload = {
                reassignList: arr,
                userRecruitmentpartnerId: activeRequAssign[0].recruitmentpartnerId,
                userId: idContextTest

            }
            let response1 = await reassignRecruiter(payload);
            response = response1;
        }

        if (response.status === 200) {
            NotificationManager.success(response.data.message, "Success", 2000);
            setreassignSaveCheck(false)
            setreassignCheck(true)
            setreassignCheckLoader(false)
            setreassignDefaultCheck(true)
        }
        else {
            NotificationManager.error("An unexpected error occurred", "Error", 2500);
            setreassignCheckLoader(false)
        }
    }
    catch(err)
    {
        NotificationManager.error("An unexpected error occurred", "Error", 2500);
         setreassignCheckLoader(false)
    }
    };
    const handelChangeReassignSave = async () => {
        let payload = {
            recruiterId: selctedRecruiter.id || activeRequAssign[0].recruiterId,
            recruitmentpartnerId: userRecruitmentpartnerId,
            validation: "RecruiterAssignedRequisition"
        }
        const dataRes = await checkExistsRequisition(payload);

        if (dataRes.status === 400 && dataRes.errorCode === 400) {
            const errorMessage = dataRes.data.message;
            NotificationManager.error(errorMessage, "Error", 2500);

        } else {
            const matchRecruiter = teamInfo.selectedRecruiter.filter((item) => item.id === selctedRecruiter.id)
            const newRecruiterOptions = teamInfo.selectedRecruiter.filter((item) => item.id != selctedRecruiter.id)

            const newRecruiterid = teamInfo.selectedRecruiterId.filter((item) => item != selctedRecruiter.id)
            setTeamInfo({ ...teamInfo, selectedRecruiter: newRecruiterOptions, selectedRecruiterId: newRecruiterid })
            setRecruiterOptions([...recruiterOptions, ...matchRecruiter])
            setopenDialogconfirmation(true)
        }
    };


    return (
        <div className='' >
            <div className='' >
                <div>
                    <Dialog
                        open={openDialogRecruiterAssingment}
                        onClose={handleCloseDialogRecruiterAssingment}
                        aria-labelledby="dialog-title"
                        maxWidth="md"
                        fullWidth={true}
                        className="add-team"
                    >
                        <div className="team_dialog_modal" >
                            <div className='team-dialog-TitleContainer'>
                            </div>
                        </div>
                        <DialogContent className="">
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Typography className='field-title-recruiter-dialog'>Recruiter assigned to an open or on-hold requisition. Do you still want to remove the recruiter?</Typography>

                                </Grid>
                            </Grid>
                        </DialogContent>
                        <DialogActions className='dialog-actionbutton-team'>
                            <button onClick={handleCloseDialogRecruiterAssingment} className="cancel-button-team">
                                No
                            </button>
                            <button
                                className={isUserDataUpdating ? 'updateButton_team_disabled' : 'updateButton-team'}
                                onClick={handleDialogRecruiterAssingment}
                                disabled={isUserDataUpdating}
                            >
                                <span>yes</span>
                                {isUserDataUpdating ? (<CircularProgress className="circularProgress_updateButton_team" />) : null}
                            </button>
                        </DialogActions>
                    </Dialog>
                    <Dialog
                        onClose={closeModalRecruiterAssign}
                        fullScreen
                        aria-labelledby="customized-dialog-title"
                        maxWidth='xl'
                        open={openDialogRecruiterAssign}
                        className='dialogBox_assignRequisitio'
                    >
                        <DialogTitle id="customized-dialog-title" className="header" onClose={closeModalRecruiterAssign}>
                            <div className='header_top'>
                                Recruiter Reassignment
                            </div>
                        </DialogTitle>
                        <DialogContent className='assignRequisitionContaine'>

                            <div className='reassign_dialog_content'>
                                <Grid item xs={4}>
                                    <Typography className='field-title-team-dialog'>Requisition Reassignment for :</Typography>
                                    <TextField variant="outlined" className='access_search' fullWidth value={selctedRecruiter.email} disabled={true} />

                                </Grid>

                                <Grid item xs={4} style={{ marginLeft: '34vw' }}>
                                    <div>

                                        <Typography className='field-title-team-dialog'>Default Reassigned Recruiter :</Typography>
                                        <Autocomplete
                                            id="combo-box-demo"
                                            value={defaultRecruiter.email}
                                            options={teamRecruiter || []}
                                            getOptionLabel={(option) => option.email}
                                            disableClearable
                                            disabled={reassignDefaultCheck}
                                            onChange={handleDefaultChange}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    variant="outlined"
                                                    placeholder="Select default recruiter first"
                                                />
                                            )}

                                        />
                                    </div>
                                </Grid>
                            </div>
                            {reassignCheckLoader &&
                                <div className="dialog-overlay">
                                    <div className="dialog-container">
                                        <div className="loader">
                                            <div className="spinner"></div>
                                        </div>
                                    </div>
                                </div>
                            }

                            <KendoGrid
                                className={classes.fontRoboto}
                                filterable={true}
                                style={{
                                    maxHeight: "60vh",
                                    width: "100%",
                                    overflow: "hidden",
                                    marginTop: '2vh'
                                }}
                                pageable={{
                                    info: true,
                                }}
                                resizable={true}
                                skip={dataStateRec.skip}
                                take={dataStateRec.take}
                                data={activeRequAssign}
                                onDataStateChange={(e) => dataStateChangeRec(e)}
                            >
                                <GridColumn
                                    title="Reference Id"
                                    field="requisitionDetail.reqreferenceId"
                                    filterable={false}
                                    width='80px'
                                />
                                <GridColumn
                                    className={classes.fontRoboto}
                                    field="no_of_positions"
                                    title="No of Positions*"
                                    filterable={false}
                                    width='60px'
                                />
                                <GridColumn
                                    className={classes.fontRoboto}
                                    // field="testCode"
                                    // title="Test Code"
                                    filterable={false}
                                    width='80px'
                                    cell={() => {
                                        return (
                                            <td className='field-title-recruiter-reassign'> Reassigned To :</td>
                                        )
                                    }}
                                />
                                <GridColumn
                                    className={classes.fontRoboto}
                                    // field="testtype.name"
                                    field="requisitionDetail.reqreferenceId"
                                    title="Recruiter Email*"
                                    filterable={false}
                                    width='170px'
                                    cell={(props) => {
                                        return (

                                            <td >
                                                <Autocomplete
                                                    id="combo-box-demo"
                                                    options={teamRecruiter}
                                                    getOptionLabel={(option) => option.email}
                                                    value={props.dataItem?.reasssingRecruiter || ""}
                                                    disableClearable
                                                    onChange={(e, val) => handelRecruiterEmailChange(val, props.dataItem)}
                                                    disabled={reassignDefaultCheck}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            variant="outlined"
                                                            placeholder='Select Recruiter email'
                                                        />
                                                    )}

                                                />
                                            </td>
                                        );
                                    }}
                                />
                                <GridColumn
                                    className={classes.fontRoboto}
                                    field="requisitionDetail.reqreferenceId"
                                    title="Recruiter Name*"
                                    filterable={false}
                                    width='120px'
                                    cell={(props) => {
                                        return (

                                            <td >
                                                <TextField
                                                    value={props.dataItem?.reasssingRecruiter.displayName || ""}
                                                    disabled={true}
                                                    variant="outlined"
                                                    className='access_search'
                                                    fullWidth
                                                    placeholder='Recruiter name'
                                                />
                                            </td>
                                        );
                                    }}
                                />
                            </KendoGrid>
                        </DialogContent>

                        <DialogActions className="dialog-actionbutton-Recruiter">


                            <button className="cancel-button-assignTeamLeader" onClick={closeModalRecruiterAssign}>
                                Cancel
                            </button>
                            <button
                                className={isUserDataUpdating ? 'updateButton-assignTeamLeader_disabled' : 'updateButton-assignTeamLeader'}
                                onClick={handelChangeReassign}
                                disabled={reassignCheck}
                            >
                                <span>Reassign</span>
                            </button>

                            <button
                                className={isUserDataUpdating ? 'updateButton-assignTeamLeader_disabled' : 'updateButton-assignTeamLeader'}
                                onClick={handelChangeReassignSave}
                                disabled={reassignSaveCheck}
                            >
                                <span>Save</span>
                            </button>



                        </DialogActions>

                    </Dialog>
                    <Dialog
                        open={openDialogconfirmation}
                        onClose={handelCloseConfirmation}
                        aria-labelledby="dialog-title"
                        maxWidth="md"
                        fullWidth={true}
                        className="add-team"
                    >
                        <div className="team_dialog_modal" >
                            <div className='team-dialog-TitleContainer'>
                            </div>
                        </div>
                        <DialogContent className="">
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Typography className='field-title-recruiter-dialog'>Do you want to remove {selctedRecruiter.email} ?</Typography>

                                </Grid>
                            </Grid>
                        </DialogContent>
                        <DialogActions className='dialog-actionbutton-team'>
                            <button onClick={handelCloseConfirmation} className="cancel-button-team">
                                No
                            </button>
                            <button
                                className={isUserDataUpdating ? 'updateButton_team_disabled' : 'updateButton-team'}
                                onClick={deleteRecruiterConfirm}
                                disabled={isUserDataUpdating}
                            >
                                <span>yes</span>
                                {isUserDataUpdating ? (<CircularProgress className="circularProgress_updateButton_team" />) : null}
                            </button>
                        </DialogActions>
                    </Dialog>
                </div>
            </div>


            <NotificationContainer />
        </div>
    )
}

export default ReassignRecruiterModal