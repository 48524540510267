import { CircularProgress } from "@material-ui/core";
import { useEffect, useState } from "react";
import AutoComplete from "../AutoComplete/AutoComplete";
import getCandidateFieldOptions from "../../apis/getCandidateFieldOptions/getCandidateFieldOptions";
import getLeaders from "../../apis/getLeaders/getLeaders";
import { candidatefieldsGet } from "../../apis/candidateField/candidateField";

const FormModel = ({
  classes,
  candidateReqId,
  recId,
  formData,
  setFormData,
  userRecruitmentpartnerId,
}) => {
  const orgId = userRecruitmentpartnerId;
  const [loader, setLoader] = useState({
    loading: false,
    saving: false,
    next: false,
  });
  const [options, setOptions] = useState({
    gender: [],
    currentStatus: [],
  });

 
  const updateFromData = (field, value) => {
    setFormData((elem) => ({ ...elem, [field]: value, update: true }));
  };

  const fetchCandidateField = async () => {
    let response;
    console.log(orgId, recId, candidateReqId);
    if (candidateReqId) {
      if(formData?.id)
        return
      response = await candidatefieldsGet(candidateReqId);
      if (response) {
        setFormData({
          gender: response?.candidateRequisitionInfo?.gender,
          TL: {
            email: response?.teamLeaderData?.email,
            id: response?.teamLeaderData?.id,
          },
          RL: {
            email: response?.recruitmentLeaderData?.email,
            id: response?.recruitmentLeaderData?.id,
          },
          currentStatus: response?.candidateRequisitionInfo?.currentStatus,
          id: response?.candidateRequisitionInfo?.candidateRequisitionId,
        });
      }
    } else {
      if(formData?.TL)
        return 
      response = await getLeaders(orgId, recId);

      setFormData((elem) => ({
        ...elem,
        TL: {
          email: response?.data?.teamLeaderData?.email,
          id: response?.data?.teamLeaderData?.id,
        },
        RL: {
          email: response?.data?.recruitmentLeaderData?.email,
          id: response?.data?.recruitmentLeaderData?.id,
        },
      }));
    }
  };

  const fetchOptions = async () => {
    if (options?.length > 0) return;

    const response = await getCandidateFieldOptions();

    if (response?.length > 0) {
      response.forEach((elem) => {
        setOptions((oldVal) => ({ ...oldVal, [elem?.name]: elem?.optionList }));
      });
    }
  };

  const fetchAllData = async () => {
    setLoader((elem) => ({ ...elem, loading: true }));
    await fetchCandidateField();
    await fetchOptions();
    setFormData((elem) => ({ ...elem, id: candidateReqId }));
    setLoader((elem) => ({ ...elem, loading: false }));
  };

  useEffect(() => {
    fetchAllData();
  }, [candidateReqId]);

  // if (!recId &&) {
  //     return (<>Select Recruiter First</>)
  // }

  return (
    <>
      {loader?.loading ? (
        <div className="loaderForEdit">
          <CircularProgress />
        </div>
      ) : (
        <>
          <AutoComplete
            title="Item of current status *"
            options={options?.currentStatus || []}
            value={formData?.currentStatus}
            className={classes.autocomplete}
            onChange={(e, v) => updateFromData("currentStatus", v)}
            getOptionLabel={(option) => option?.name || option}
            // disabled={disabled}
          />
          {formData?.TL?.email &&
            <AutoComplete
            title="TL email"
            key={formData?.TL?.email}
            //   options={options.TLname}
            value={formData?.TL?.email}
            className={classes.autocomplete}
            // onChange={(e, v) => updateFromData("TLname", v)}
            // getOptionLabel={(option) => option?.type || ""}
            disabled={true}
            />
          }
          <AutoComplete
            title="RL email"
            key={formData?.RL?.email}
            //   options={options.RLname}
            value={formData?.RL?.email}
            className={classes.autocomplete}
            // onChange={(e, v) => updateFromData("RLname", v)}
            // getOptionLabel={(option) => option?.type || ""}
            disabled={true}
          />
          <AutoComplete
            title="Gender*"
            options={options.gender || []}
            value={formData?.gender}
            className={classes.autocomplete}
            onChange={(e, v) => updateFromData("gender", v)}
            getOptionLabel={(option) => option?.name || option}
            // disabled={disabled}
          />
        </>
      )}
    </>
  );
};

export default FormModel;
